import { EmprendedorAG } from './../../authguards/EmprendedorAG';
import { InversorAG } from './../../authguards/InversorAG';
import { AdminAuthGuard } from 'src/app/authguards/AdminAuthGuard';
import { UsuarioAG } from './../../authguards/UsuarioAG';
import {
    NovedadService,
    IActualizable,
} from './../../common/service/novedades.service';
import { TranslateService } from './../../common/service/translate.service';
import { Usuario } from 'src/app/common/model/Usuario';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './../../common/service/auth.service';
import { Novedad } from './../../../app/common/model/Novedad';
import { BaseService } from './../../common/service/base.service';
import { Injectable } from '@angular/core';
import { EmprendimientoAG } from 'src/app/authguards/EmprendimientoAG';

@Injectable({
    providedIn: 'root',
})
export class MenuConfigService extends BaseService implements IActualizable {
    public baseName(): string {
        return 'menu-config';
    }
    constructor(
        private authService: AuthService,
        private router: Router,
        private translateService: TranslateService,
        private userAG: UsuarioAG,
        private novedadService: NovedadService,
        private adminAG:AdminAuthGuard,
        private emprendedorAG:EmprendedorAG,
        private emprendimientoAG:EmprendimientoAG,
        private inversorAG:InversorAG
    ) {
        super();
        setTimeout(() => {
            this.init();
        }, 1);
    }
    next(n: Novedad) {
        this.updateBS(this.generarMenu(this.authService.usuario));
    }
    destroy() {
        this.menuBS.next([]);
    }
    logout = () => {
        this.authService.logout().then((l) => {
            this.router.navigate(['login']);
        });
    };
    public menuBS: BehaviorSubject<MenuItem[]> = new BehaviorSubject([]);
    public rutasBS: BehaviorSubject<MenuItem[]> = new BehaviorSubject([]);
    private generarMenu(usuario: Usuario) {
        var items: any[] = [];
        let $this = this;
        items = [];
        if (usuario && this.translateService.data) {
            items = [
                {
                    label: 'Configuración',
                    icon: 'pi pi-cog',
                    visible: this.adminAG.esVisible(usuario),
                    items: [
                        {
                            label: this.translateService.get('ESTADO_EVALUACION_PLR'),
                            icon: 'fa-solid fa-flag',
                            visible: this.adminAG.esVisible(usuario),
                            routerLink: ['estado-evaluacion'],
                        },
                        {
                            label: this.translateService.get('TIPO_DOCUMENTACION_ADJUNTA_PLR'),
                            icon: 'fa-solid fa-file-lines',
                            visible: this.adminAG.esVisible(usuario),
                            routerLink: ['tipo-documentacion-adjunta'],
                        },
                        {
                            label: this.translateService.get('CATEGORIA_INVERSOR_PLR'),
                            icon: 'fa-solid fa-flag',
                            visible: this.adminAG.esVisible(usuario),
                            routerLink: ['categoria-inversor'],
                        },
                        {
                            label: this.translateService.get('TIPO_KPI'),
                            icon: 'fa-solid fa-chart-simple',
                            visible: this.adminAG.esVisible(usuario),
                            routerLink: ['tipo-kpi'],
                        },
                        {
                            label: this.translateService.get('NOTIFICACION_PLR'),
                            icon: 'fa-solid fa-envelope',
                            visible: this.adminAG.esVisible(usuario),
                            routerLink: ['notificacion'],
                        },
                    ]
                },
                {
                    label: 'Entidades',
                    icon: 'pi pi-home',
                    visible: this.adminAG.esVisible(usuario),
                    items: [
                        {
                            label: this.translateService.get('USUARIOS'),
                            icon: 'fa fa-users',
                            visible: this.adminAG.esVisible(usuario),
                            routerLink: ['usuario'],
                        },
                        {
                            label: this.translateService.get('INVERSOR_PLR'),
                            icon: 'fa fa-money',
                            visible: this.adminAG.esVisible(usuario),
                            routerLink: ['inversor'],
                        },
                        {
                            label: this.translateService.get('EMPRENDEDOR_PLR'),
                            icon: 'fa-regular fa-lightbulb',
                            visible: this.adminAG.esVisible(usuario),
                            routerLink: ['emprendedor'],
                        },
                        {
                            label: this.translateService.get('EMPRENDIMIENTO_PLR'),
                            icon: 'fa fa-gears',
                            visible: this.adminAG.esVisible(usuario),
                            routerLink: ['emprendimiento'],
                        },
                        
                    ]
                }
            ]
            if(usuario.tieneRol(["ROLE_INVERSOR"])){
                const miPerfil ={
                    label: 'Mi perfil Inversor',
                    icon: 'pi pi-money',
                    visible: this.inversorAG.esVisible(usuario),
                    items: [
                        {
                            label: this.translateService.get('Usuario'),
                            visible: true,
                            routerLink: ['usuario/vista'],
                            queryParams:{"id":usuario?.id}
                        },
                        {
                            label: this.translateService.get('Inversor'),
                            visible: true,
                            routerLink: ['inversor/vista'],
                            queryParams:{"id":usuario?.inversor?.id}
                        },
                    ]
                }
                items.push(miPerfil);
            }
            if(usuario.tieneRol(["ROLE_EMPRENDEDOR"])){
                const miPerfil ={
                    label: 'Mi perfil Emprendedor',
                    icon: 'fa-regular fa-thought-bubble',
                    visible: this.emprendedorAG.esVisible(usuario),
                    items: [
                        {
                            label: this.translateService.get('Usuario'),
                            visible: true,
                            routerLink: ['usuario/vista'],
                            queryParams:{"id":usuario?.id}
                        },
                        {
                            label: this.translateService.get('Emprendedor'),
                            visible: true,
                            routerLink: ['emprendedor/vista'],
                            queryParams:{"id":usuario?.emprendedor?.id}
                        },
                        {
                            label: this.translateService.get('Mis emprendimientos'),
                            visible: true,
                            routerLink: ['emprendimiento'],
                            queryParams:{"emprendedorId":usuario?.emprendedor?.id}
                        },
                    ]
                }
                items.push(miPerfil);
            }
            
        } else {
        }

        return items;
    }

    public override init = () => {
        let $this: IActualizable = this;
        this.novedadService.registrarObservador('usuario', $this);
        this.authService.onLogin.subscribe((u) => {
            const menu = this.generarMenu(this.authService.usuario);
            this.updateBS(menu);
        });
        this.translateService.upateData.subscribe((r) => {
            const menu = this.generarMenu(this.authService.usuario);
            this.updateBS(menu);
        });
    };
    private updateBS(menu: any[]) {
        this.menuBS.next(menu);
        this.rutasBS.next(
           menu.reduce(
                (flat, toFlat) =>
                    flat.concat(
                        toFlat.items
                            ? toFlat.items.filter(
                                  (i: any) => !i.items && !i.skipRouting
                              )
                            : toFlat.skipRouting
                            ? []
                            : [toFlat]
                    ),
                []
            )
        );
    }
}
