import { StringUtils } from './../utils/string-utils';
import { IDescriptivo } from './IDescriptivo';
import { IOpcionChip } from 'src/app/common/components/selector-multiple-chips/IOpcionChip';
import { Identificable } from './Identficable';
import { SelectItem } from 'primeng/api';


export class Descriptivo implements SelectItem, Identificable, IOpcionChip, IDescriptivo {
    public searchStr;
    constructor(public codigo?: string, public descripcion: string = "", public id?: number) {
        this.searchStr = this.codigo+"_"+this.descripcion;
    }
    public static _TODOS: Descriptivo = new Descriptivo("-1", "Todos");

    // public static _SIN_ASIGNAR: Descriptivo = new Descriptivo("null", "SIN ASIGNAR");
    // public static _SIN_DEFINIR: Descriptivo = new Descriptivo("SD", "Sin Definir");
    public static TODOS(): Descriptivo { if (!Descriptivo._TODOS) Descriptivo._TODOS = new Descriptivo("-1", "Todos"); return Descriptivo._TODOS };
    // public static SIN_ASIGNAR(): Descriptivo { if (!Descriptivo._SIN_ASIGNAR) Descriptivo._SIN_ASIGNAR = new Descriptivo("null", "SIN ASIGNAR"); return Descriptivo._SIN_ASIGNAR };
    // public static SIN_DEFINIR(): Descriptivo { if (!Descriptivo._SIN_DEFINIR) Descriptivo._SIN_DEFINIR = new Descriptivo("SD", "Sin Definir"); return Descriptivo._SIN_DEFINIR };


    public static fromData(a: any): Descriptivo | undefined {
        return a ? new Descriptivo(a.codigo, a.descripcion, a.id) : undefined;
    }



    get label(): string {
        return this.descripcion;
    }

    get value(): any {
        return this;
    }


    get sinCaracteresEspeciales() {
        return this.descripcion ? StringUtils.getSinTildes(this.descripcion) : "";
    }

    get descripcionComparable() {
        return this.descripcion ? this.descripcion.toLowerCase() : "";
    }

    public static getCodigo(o: any, field: string = "codigo", ifnot: string = ""): string {
        return o ? o[field] : ifnot;
    }

    public toString() {
        return this.descripcion;
    }

    public get key(){
        return this.id || this.codigo || this.descripcion;
    }


}