import { lastValueFrom } from 'rxjs';
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { Filtro } from 'src/app/common/model/Filtro';
import { BufferedService } from './BufferedService';
import { LoadingService } from './loading-data-service.service';
export abstract class BufferedDescriptivoService<E extends Descriptivo> extends BufferedService<E>{

    public getDescriptivosByDesc(query: any, customLoading?: LoadingService): Promise<E[]> {
        if (customLoading) customLoading.addLoadingCount(); else this.loadingService.addLoadingCount();
        return lastValueFrom(this.http.get(this.getBaseURL() + "getByDesc/?val=" + query)).then(r => {
            if (customLoading) customLoading.susLoadingCount(); else this.loadingService.susLoadingCount();
            return this.parse(r)
        }).catch((e) => this.handleError(e, customLoading));
    }
    getDescriptivos(filter: Filtro = new Filtro("", {}, 0, 10000), customLoading?: LoadingService): Promise<Descriptivo[]> {
        let p: Promise<Descriptivo[]>;
        if (customLoading) customLoading.addLoadingCount(); else this.loadingService.addLoadingCount();
        p = this.verifyUpdated(customLoading).then(
             (r)=>this.getData(filter, customLoading).then(r => { return r.map(p => Descriptivo.fromData(p)) }));
        p.finally(() => {
            if (customLoading) customLoading.susLoadingCount(); else this.loadingService.susLoadingCount();
        });
        return p;
    }
    public getByCodigo(codigo: string, customLoading?: LoadingService): Promise<E> {
        let $this = this;
        if (customLoading) customLoading.addLoadingCount(); else this.loadingService.addLoadingCount();
        let p;
        p = this.verifyUpdated(customLoading).then( (r)=>this.getData(null, customLoading)).then(r => {
            return r.find(d => d.codigo == codigo);
        })
        p.finally(() => {
            if (customLoading) customLoading.susLoadingCount(); else this.loadingService.susLoadingCount();
        });
        return p;
    }
}