import { Component, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Parametrico } from '../../model/Parametrico';
import { GenericParemtricoService } from '../../service/generic-parametrico.service';
import { MessagesService } from '../../service/messages-data-service.service';
import { NovedadService } from '../../service/novedades.service';
import { ParametricoService } from '../../service/parametricos.service';

import { SessionComponent } from "../session-component.component";



@Component({
	selector: 'gestor-parametrico',
	templateUrl: 'gestor-parametrico.component.html',
	styleUrls: ['gestor-parametrico.component.less',]
})

export class GestorParametricoComponent extends SessionComponent implements OnDestroy, OnInit {

	@Input()
	public override editable: boolean = true;

	@Input()
	public goBack: boolean = true;
	@Input()
	public readonly: boolean = false;
	@Input()
	public isModal: boolean = false;
	@Output()
	public onGuardar: EventEmitter<Parametrico> = new EventEmitter<Parametrico>();

	public defTitulo: string = "";

	@Input()
	public title: string;

	@Output()
	public onCancelar: EventEmitter<boolean> = new EventEmitter<boolean>();


	private _tipo: string;
	public get tipo(): string {
		return this._tipo;
	}
	@Input()
	public set tipo(v: string) {
		this._tipo = v;
		if (v && !this._service) {
			this.defaultService = new GenericParemtricoService(this.novedades, this.authService);
			this.defaultService.paramId = v;
		}
	}

	private defaultService: GenericParemtricoService;

	private _service: ParametricoService<any>;
	public get service(): ParametricoService<any> {
		return this._service ? this._service : this.defaultService;
	}
	@Input()
	public set service(v: ParametricoService<any>) {
		this._service = v;
	}


	constructor(private confService: ConfirmationService, messagesService: MessagesService, private novedades: NovedadService) {
		super(messagesService);
	}

	private _itemEditado: Parametrico = new Parametrico();
	public get itemEditado(): Parametrico {
		return this._itemEditado;
	}
	@Input()
	public set itemEditado(v: Parametrico) {
		this._itemEditado = v;
		if (!v) {
			this._itemEditado = new Parametrico();
		}
	}


	public isValid(item) {
		let valido = true;
		if (item.descripcion == undefined || !item.descripcion) {
			valido = this.error("Indique una descripción");
		}
		return valido;
	}
	ngOnInit() {

	}
	override ngOnDestroy() {

	}

	public onGuardado(item: Parametrico) {

		this.onGuardar.emit(item);

	}
	public onCancelado() {
		this.onCancelar.emit();
	}
}