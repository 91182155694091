<button pButton (click)="handleClick($event)" class="p-button-outlined bandera-contenedor">
    <img class="bandera" [src]="idioma?.imagen" alt="Sin imagen" [ngClass]="{ display: readOnly }" pTooltip="{{ idioma?.descripcion }}" *ngIf="idioma" />
</button>

<p-overlayPanel styleClass="overlay-idioma" #op appendTo="body">
    <ng-template pTemplate>
        <div class="opcion-idioma " *ngFor="let opcion of idiomas" (click)="seleccionar(opcion); op.hide()">
            <img src="{{ opcion?.imagen }}" alt="" style="width: 1.5em; margin-right: 0.5em;"/>
            <span>{{ opcion?.descripcion }}</span>
        </div>
    </ng-template>
</p-overlayPanel>
