import { Injectable } from '@angular/core';
import { Parametrico } from '../model/Parametrico';
import { Filtro } from './../model/Filtro';
import { AuthService } from './auth.service';
import { LoadingService } from './loading-data-service.service';
import { MessagesService } from './messages-data-service.service';
import { NovedadService } from './novedades.service';
import { ParametricoService } from './parametricos.service';
@Injectable()
export class GenericParemtricoService extends ParametricoService<Parametrico>{


    private _paramId: string;
    public get paramId(): string {
        return this._paramId;
    }
    public set paramId(v: string) {
        this._paramId = v;
        this.loadData();
    }
    public baseName(): string {
        return this.paramId;
    }
    public parseToEnt(data: any): Parametrico {
        return Parametrico.fromData(data);
    }
    public newEnt(): Parametrico {
        return new Parametrico();
    }
    constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
        super(novedadesService, authService, messServ);
    }

    override getDescriptivos(filter?: Filtro, customLoading?: LoadingService) {
        if (!this.paramId) return Promise.resolve([]);
        return super.getDescriptivos(filter, customLoading);
    }
    override getAll = (filtro?: Filtro, customLoading?: LoadingService): Promise<Parametrico[]> =>{
        if (!this.paramId) return Promise.resolve([]);
        return super.getAll(filtro, customLoading);
    }

    override getHabilitados = (filtro?: Filtro, customLoading?: LoadingService): Promise<Parametrico[]>=> {
        if (!this.paramId) return Promise.resolve([]);
        return super.getHabilitados(filtro, customLoading);
    }

    protected override loadData(customLoading?: LoadingService) {
        if (!this.paramId) return Promise.resolve([]);
        return super.loadData(customLoading)

    }

}