<div class="p-inputgroup descriptivo-material-selector field" 
    [ngClass]="{ readonly: readonly, 'con-addon': limpiable && !disabled && !readonly && seleccionado }">
    <span class="p-inputgroup-addon" *ngIf="limpiable && !disabled && !readonly && seleccionado">
        <i class="fa fa-eraser clickable" (click)="clean($event)"></i>
    </span>
    <span class="p-fluid p-float-label" #inputDS>
        <p-autoComplete  *ngIf="!readonly && !disabled "
            [dataKey]="'codigo'"
            [(ngModel)]="seleccionado"
            [suggestions]="dataFiltrada"
            (completeMethod)="filtrar($event.query)"
            field="descripcion"
            (onBlur)="autoselect($event)"
            [required]="required"
            [readonly]="readonly || disabled"
            [forceSelection]="true"
            appendTo="body"
            vitualScroll="true"
            [delay]="500"
            [size]="100"
            [dropdown]="true"
            [virtualScrollItemSize]="50"
            [placeholder]="placeHolder"
        >
            <ng-template let-option pTemplate="item">
                <ng-container *ngTemplateOutlet="itemTemplate ? itemTemplate : itemTemplateDefault; context: { $implicit: option }"> </ng-container>
            </ng-template>
            <ng-template let-option pTemplate="selectedItem">
                <ng-container *ngTemplateOutlet="itemTemplate ? itemTemplate : itemTemplateDefault; context: { $implicit: option }"> </ng-container>
            </ng-template>
        </p-autoComplete>
        <input [class.p-filled]="seleccionado!==undefined" id="descripcion" *ngIf="readonly || disabled"  pInputText [(ngModel)]="safeDescripcion" type="text" [readonly]="'true'" />										
        <label *ngIf="label && !placeHolder" for="descripcion">{{ label }} <span class="danger" *ngIf="required && !readonly">*</span></label>
    </span>

    <span class="p-inputgroup-addon" *ngIf="loadingService.loading | async">
        <i class="pi pi-circle-o-notch fa-spin fa-fw fa-2x"></i>
    </span>
</div>

    
<p-dialog
    [(visible)]="editando"
    appendTo="body"
    [modal]="true"
    #dialDetail
    [styleClass]="'no-header gestor-dialog'"
    (onShow)="onDialogShow($event, dialDetail)"
>
    <ng-container *ngIf="editando">
        <ng-container *ngTemplateOutlet="gestor ? gestor : defaultGestor; context: ctx"></ng-container>
    </ng-container>
</p-dialog>
<ng-template #defaultGestor>
    <span>no se definió formulario</span>
</ng-template>
<ng-template #itemTemplateDefault let-option> <i [class]="option?.icon ? option?.icon : 'dummy'"></i>{{ option?.descripcion }} </ng-template>

<p-contextMenu [target]="inputDS" [model]="menuItems" appendTo="body" [baseZIndex]="2000" *ngIf="!readonly"> </p-contextMenu>
