import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Filtro } from "../model/Filtro";
import { Identificable } from "../model/Identficable";
import { Novedad } from "../model/Novedad";
import { AuthService } from "./auth.service";
import { LoadingService } from "./loading-data-service.service";
import { MessagesService } from "./messages-data-service.service";
import { IActualizable, NovedadService } from "./novedades.service";
import { ServicioAbstract } from "./service.service";

@Injectable(
  {
    providedIn: 'root',
  }
)
export abstract class BufferedService<E extends Identificable> extends ServicioAbstract<E> implements IActualizable {
  protected _data: any[];
  public cantidad: number = 0;
  private _ultimaNovedad: Novedad;
  private loaded = false;
  protected updating: Promise<any[]>;
  public data: BehaviorSubject<any[]> = new BehaviorSubject([]);
  constructor(protected novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
    super(messServ);
    if (this.novedadesService) {
      this.novedadesService.registrarObservador(this.baseName(), this);
    } else {
      console.log("NO SE PUDO REGISTRAR PARA ACTUALIZAR");
    }
    authService.onLogout.subscribe((v) => {
      this.destroy();
    });
  }
  public override init() {
    this.loadData(new LoadingService());
  }
  public destroy() {
    this._data = null;
    this.updating = null;
  }
  public getTotalCount() {
    return this._data?.length || 0;
  }
  public getData = (filter?: Filtro, customLoading?: LoadingService): Promise<E[]>=> {
    const fil = async ()=>{
      const size = filter?.size || 9000;
      const page = filter?.page || 0
      const resultado = this._data?.length? (filter? filter.apply(this._data): this._data): [];
      const from =  page * size;
      const to = (from + size) > resultado.length ? resultado.length : from + size; 
      return Promise.resolve(resultado.slice(from,to));
    }
    if(!this.loaded) return this.loadData()
    if(this.updating) return this.updating.then(fil);
    
    return fil();
    
  }
  protected fill(filtro = new Filtro(null,{},0,10000,'peso,descripcion'),customLoading?){
    return super.getAll(filtro, customLoading).then((r) => {
      this._data = this.parse(r);
      this.data.next(r);
      return r;
    });
  }
  updateById(id: number, customLoadingService?:LoadingService) {
		return super.getById(id).then(r=>{
      const index = this._data.findIndex((e) => e.id == r.id);
      if(!index) return;
      this._data[index] = r;
      this.data.next(this._data);
    })
	}
  public override getAll = (filter?: Filtro, customLoading?: LoadingService): Promise<E[]> =>{
    return this.verifyUpdated(customLoading).then( (r)=> 
    this.getData(filter,customLoading));
  }
  public forceRefresh() {
    this._data = [];
    this.loadData(new LoadingService());
  }
  public next(n: Novedad) {
    // if (n && n.key != this._ultimaNovedad?.key) {
    if (n.idEntidad && this._data.some((e) => e.id == n.idEntidad)) {
      super.getById(n.idEntidad, new LoadingService()).then((r) => {
        this._data[this._data.findIndex((e) => e.id == r.id)] = r;
        this.data.next(this._data);
      });
    } else if (!n.idEntidad) {
      this.loadData();
    } else{
      super.getById(n.idEntidad, new LoadingService()).then((r) => {
        this._data.push(r);
        this.data.next(this._data);
      });
    }
    // }
  }
  public override async guardarConArchivo(e: E, file: File, customLoading?: LoadingService) {
    var p = super.guardarConArchivo(e, file, customLoading).then((v) => {
      if (!e.id && v.id) {
        this._data.push(v);
      } else {
        this._data[this._data.findIndex((d) => d.id == v.id)] = v;
      }
      this.data.next(this._data);
      return v;
    });
    return p;
  }
  public override async guardar(e: E, customLoading?: LoadingService) {
    var p = super.guardar(e, customLoading).then((v) => {
      if (!e.id && v.id) {
        this._data.push(v);
      } else {
        this._data[this._data.findIndex((d) => d.id == v.id)] = v;
      }
      this.data.next(this._data);
      return v;
    });
    return p;
  }

  public override async eliminar(e: number, customLoading?: LoadingService) {
    var p = super.eliminar(e, customLoading).then((v) => {
      this._data = this._data.filter((d) => d.id != e);
      this.data.next(this._data);
    });
    return p;
  }

  override getById(id: number, customLoading?: LoadingService, idioma?: string): Promise<E> {
    if (customLoading) {
      customLoading.addLoadingCount();
    } else this.loadingService.addLoadingCount();
    return this.verifyUpdated(customLoading).then((res)=>{
      return this.getData()
      .then((r) => {
        const d =r.filter((e) => e.id == id)[0];
        return this.parseSingle(d);
      })
      .finally(() => {
        if (customLoading) {
          customLoading.susLoadingCount();
        } else this.loadingService.susLoadingCount();
      })
    }).finally(() => {
      if (customLoading) {
        customLoading.susLoadingCount();
      } else this.loadingService.susLoadingCount();
    });
  }
  async verifyUpdated(customLoading?:LoadingService) : Promise<Boolean>{
    const novedades = await this.novedadesService.check().catch((e)=>{
      if(customLoading) customLoading.susLoadingCount(); else this.loadingService.susLoadingCount();
      return Promise.resolve([])
    });    
    const novedad = novedades.find((n)=> n.tipo == this.baseName());
    if(novedad || !this._data?.length) await this.loadData(customLoading);
    return Promise.resolve(true);
  }
  protected fillData: (f, l) => Promise<any[]> = this.fill;
  protected loadData(customLoading?: LoadingService): Promise<E[]> {
    this.loaded = true;
    if (!this.updating){
      this.updating = this.fillData(new Filtro(null, {}, 0, 9000), customLoading)
      .then((r: any) => {
        this._data = [...r];
        this.data.next(this._data);

        return this._data;
      }).catch(e => {
        return Promise.reject(e);
      })
      .finally(() => {
        this.updating = null;
        if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
      });
    }
    return this.updating;
  }
}
