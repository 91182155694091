import { CommonModule } from '@angular/common';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DescriptivoMaterialSelectorComponent } from './descriptivo-material-selector.component'

const pipe = []
const directives = []
const components = [DescriptivoMaterialSelectorComponent]

@NgModule({
    imports: [AutoCompleteModule, DialogModule, ContextMenuModule, FormsModule, ReactiveFormsModule, CommonModule ],
    providers: [],
    declarations: [...pipe, ...directives, ...components],
    exports: [...pipe, ...directives, ...components],
})
export class DescriptivoModule {}
