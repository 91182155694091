import { LoadingPanelComponent } from './common/components/loading-panel/loading-panel.component';
import { MessagesModule } from './common/components/messages/messages.module';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { TranslatePipe } from './common/pipes/translate.pipe';
import { environment } from './../environments/environment';
import { CoreModule } from './core.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { StyleClassModule } from 'primeng/styleclass';
import { GridsterModule } from 'angular-gridster2';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { DEFAULT_CURRENCY_CODE, Injector, LOCALE_ID, NgModule } from '@angular/core'
import { CommonModule, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RecaptchaModule } from 'ng-recaptcha'
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonComponentsModule } from './common-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppLayoutModule } from './layout/app.layout.module';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { JwtInterceptor } from './interceptors/JWTInterceptor';
import { GlobalInjector } from './common/GlobalInjector';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { MessagesService } from './common/service/messages-data-service.service';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs);
export function ID() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substring(2, 9)
}
@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        AppRoutingModule,        
        AppLayoutModule,
        ToastModule,
        CommonModule,
        CommonComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MessagesModule,
        RecaptchaModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireStorageModule,
        AngularFireDatabaseModule,
        GridsterModule,
        StyleClassModule,
        ServiceWorkerModule.register('combined-sw.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately',
        }),
    ],
    providers: [
        TranslatePipe,
        MessageService,
        MessagesService,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        SwUpdate,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        { provide: LOCALE_ID, useValue: 'es' }
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor(private injector: Injector) {
        GlobalInjector.InjectorInstance = this.injector
        //momenttz.tz.setDefault("UTC");
    }
}
