import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { lastValueFrom, firstValueFrom } from 'rxjs';
import { share } from 'rxjs/operators';
import { Descriptivo } from '../../common/model/Descriptivo';
import { Filtro } from './../model/Filtro';
import { BaseService } from './base.service';
import { LoadingService } from './loading-data-service.service';
import { MessagesService } from './messages-data-service.service';

@Injectable()
export abstract class ServicioAbstract<E> extends BaseService {


    constructor(messages?: MessagesService, loadingService?: LoadingService) {
        super(messages, loadingService)
    }



    protected parseDescriptivos(res: any): Descriptivo[] {

        let descriptivos: Descriptivo[] = [];
        if (res !== null) {
            var $this = this;
            descriptivos = res.map(d => Descriptivo.fromData(d));
        }
        return descriptivos;


    }



    public abstract override baseName(): string;
    public abstract parseToEnt(data): E;
    public abstract newEnt(): E;
    public count(filtro: Filtro, customLoading?: LoadingService): Promise<number> {
        let $this = this;
        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http.post(this.getBaseURL("count"), filtro.json))
            .then((r: any) => {                
                return r ? r : 0;
            }).catch((e) => this.handleError(e, customLoading)).finally(()=>{
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
            });

    }

    getAll(filtro: Filtro = new Filtro('dummy', {}), customLoading?: LoadingService): Promise<E[]> {
        let $this = this;
        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http.post(this.getBaseURL('all'), filtro.json),{defaultValue:[]})
            .then(r => {
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
                return $this.parse(r)
            }).catch((e) => this.handleError(e, customLoading)).finally(()=>{
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
            });
    }
    getById(id: number, customLoading?: LoadingService, idioma?: string): Promise<E> {
        let $this = this;
        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http.get(this.getBaseURL() + id + (idioma ? "?idioma=" + idioma : "")))
            .then(r => {
                return $this.parseSingle(r)
            }).catch((e) => this.handleError(e, customLoading)).finally(()=>{
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
            });
    }

    parse = (res: any): any[] =>{
        if (res) {
            let result = res.map(f => this.parseToEnt(f));
            return result;
        }
        return [];

    }

    parseSingle = (res: any): E =>{
       if (res !== null) {
            return this.parseToEnt(res);
        }
        return null
    }
    guardarConArchivo(e: E, file: File, customLoading?: LoadingService) {
        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        if (e["id"]) {

            const frmData = new FormData();
            if (file) frmData.append("file", file);
            let vo = this.parseToEnt(e);
            if (vo["profilePic"]) vo["profilePic"]["previewPicPath"] = null;
            frmData.append("vo", encodeURIComponent(JSON.stringify(vo)));
            const request = new HttpRequest(
                "PUT", this.getBaseURL(),
                frmData,
            );
            return lastValueFrom(this.http.request(request))
                .then((r: any) => {
                    return this.parseSingle(r["body"]);
                }).catch((e) => this.handleError(e, customLoading)).finally(()=>{
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
            });
        } else {
            const frmData = new FormData();
            if (file) frmData.append("file", file);
            let vo = this.parseToEnt(e);
            if (vo["profilePic"]) vo["profilePic"]["previewPicPath"] = null;
            frmData.append("vo", encodeURIComponent(JSON.stringify(vo)));
            const request = new HttpRequest(
                "POST", this.getBaseURL(),
                frmData,
            );
            return lastValueFrom(this.http.request(request))
                .then((r: any) => {
                    return this.parseSingle(r["body"]);
                }).catch((e) => this.handleError(e, customLoading)).finally(()=>{
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
            });

        }

    }
    guardar(e: E, customLoading?: LoadingService) {
        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        if (e["id"]) {

            return lastValueFrom(this.http.put(this.getBaseURL(), e))
                .then((r) => {
                    return this.parseSingle(r)
                }).catch((e) => this.handleError(e, customLoading)).finally(()=>{
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
            });

        } else {


            return lastValueFrom(this.http.post(this.getBaseURL(), e))
                .then((r) => {
                    return this.parseSingle(r)
                }).catch((e) => this.handleError(e, customLoading)).finally(()=>{
                    if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
                });

        }

    }
    eliminar(id: number, customLoading?: LoadingService) {

        if (customLoading) { customLoading.addLoadingCount(); }
        else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http.delete(this.getBaseURL() + id))            
            .then((r) => this.handleOk(r, customLoading)).catch((e) => this.handleError(e, customLoading)).finally(()=>{
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
            });
    }

    bulkUpload(file: File):any {

    }




}