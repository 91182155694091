<div
    class="model-gestor {{
        styleClass
    }}  surface-section {{isModal? 'px-2 py-5' : 'px-6 py-6'}} "
    [ngClass]="{ modal: isModal, 'surface-section border-1 border-round ':!isModal }" style="position: relative;"
>
    <div class="contenedor-titulo" *ngIf="!hideTitle">
        <div
            class="model-title"
            #title
            [hidden]="title.childNodes.length === 0 || hideTitle"
        >
            <ng-content select="[model-type=title]"></ng-content>
        </div>
        <div
            class="title"
            *ngIf="title.childNodes.length === 0 && !hideTitle && item"
        >
            <div class="grid justify-content-end">
                <div class="col lg:col title-content flex">
                    <h2>{{ finalTitle }}</h2>
                    <button
                        pButton
                        icon="fa fa-pencil"
                        (click)="editar()"
                        *ngIf="readonly && editable"
                        class="p-button-primary"
                        style="margin-left: 2em"
                    ></button>
                    <selector-idioma
                        [(ngModel)]="idiomaSeleccionado"
                        name="idioma"
                        *ngIf="multilenguaje"
                        style="margin-left: 2em"
                        [disabled]="!item?.id"
                    ></selector-idioma>
                </div>

                <div
                    class="col-fixed text-right"
                    style="width: 10em"
                    *ngIf="verAcciones"
                >
                    <button
                        pButton
                        [label]="isMobile() ? null : ('ACCIONES' | translate)"
                        [icon]="'fa fa-list'"
                        (click)="menuAccionesDefault.toggle($event)"
                    ></button>
                </div>
                <div class="col-fixed text-right actions help-button" *ngIf="hasHelp()">
                    <button
                        pButton
                        tooltipPosition="bottom"
                        pTooltip="{{ 'AYUDA' | translate }}"
                        class="p-button-primary"
                        icon="fa fa-question"
                        (click)="goToHelp()"
                    ></button>
                </div>
                <div class="col-12 subheader" *ngIf="subheader">
                    <span>{{ subheader }}</span>
                </div>
            </div>
        </div>
    </div>

    

    <div class="model-dataContent p-fluid" #dataContent *ngIf="item">
        <ng-content select="[model-type=dataContent]"></ng-content>
    </div>
    <div class="col-12 flex justify-content-end gap-3 buttons-gestor" *ngIf="!hideButtons">
        <button
            pButton
            label="{{ labelCancelar }}"
            (click)="cancelar()"
            class="p-button-danger"
        ></button>
        <button
            class="p-button-success"
            pButton
            label="{{ 'GUARDAR' | translate }}"
            (click)="isSaving = true; guardar()"
            [disabled]="!modificado || isSaving"
            *ngIf="!readonly"
        ></button>
    </div>
</div>

<p-menu
    #menuAccionesDefault
    [popup]="true"
    [model]="menuItemAcciones"
    appendTo="body"
></p-menu>
<p-dialog
    *ngIf="mostrarAuditar && auditableItem"
    header="Auditoría"
    [(visible)]="mostrarAuditar"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '50vw' }"
>
    <div class="grid">
        <div class="col-12">
            <span class="overflow oneline">{{
                "MODIFICADO_POR" | translate
            }}</span>
            <input
                pInputText
                type="text"
                disabled
                [(ngModel)]="auditableItem.lastModifiedBy"
            />

            <span class="overflow oneline">{{
                "FECHA_MODIFICACION" | translate
            }}</span>
            <input
                pInputText
                type="text"
                disabled
                [(ngModel)]="auditableItem.lastModifiedDate"
            />
        </div>
    </div>
    <p-footer>
        <button
            pButton
            class="boton"
            (click)="mostrarAuditar = false"
            label="Cerrar"
        ></button>
    </p-footer>
</p-dialog>

<p-confirmDialog [key]="'genConfMG'" [appendTo]="'body'" 
icon="fa fa-question-circle" acceptLabel="Si" rejectLabel="No"></p-confirmDialog>