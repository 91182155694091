import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { saveAs } from 'file-saver';

@Injectable({
    providedIn:"root"
})
export class ExcelDownloader{

    public downloadData(toDownload:any[], name?:string, skip:any = {}, columns?:any[]){
        import("xlsx").then(async xlsx => {
            const data = toDownload;
            const transformed = data.map(d =>{
                let el = {}
                if(columns?.length){
                    columns.filter(field => !skip[field]).forEach(element => {
                        el[element.header || element.field] = this.parseElement(d,element.field);
                    });
                }else{
                    for (const key in d) {
                        if (Object.prototype.hasOwnProperty.call(d, key) && !skip[key]) {                            
                            el[key] = this.parseElement(d,key);
                        }
                    
                    }
                }
               
                return el;
            });
            const worksheet = xlsx.utils.json_to_sheet(transformed);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, name || "datosExportados"+moment().format('YYYYMMDDhhss'));
        });
    }
    parseElement(d,key,){
        const element = d[key];
        if(element instanceof Descriptivo){
            return element['descripcion'];
        } else if( element instanceof Date){
           return  moment(element).format('DD-MM-YYYY');
        }else {
            return element;
        }
    }
    saveAsExcelFile(buffer: any, fileName: string): void {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data: Blob = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
    }
}