
import { IOpcionChip } from '../components/selector-multiple-chips/IOpcionChip';
import { StringUtils } from './../utils/string-utils';
import { Descriptivo } from './Descriptivo';
import { ResumenModel } from './ResumenModel';
export class Parametrico extends Descriptivo implements ResumenModel, IOpcionChip {

    public static UNIDAD_PRODUCTO_ID = "unidadproducto";
    static NUEVO: Parametrico = new Parametrico(null, "NN", "Nuevo", true, true, 0, 'fa fa-plus');
    public override searchStr: string ="";
    constructor(id?: number, codigo?: string, descripcion?: string,
        public habilitado: boolean = true,
        public esSistema: boolean = false,
        public peso: number = 99,
        public icon?: string,
        public esDefault: boolean = false) {
        super(codigo, descripcion,id);
        this.searchStr = StringUtils.getSinTildes([this.id, this.codigo, this.descripcion].join("_"));
    }
    entityName(): string {
        return this.tipoParametro();
    }

    public tipoParametro(): string {
        return "parametrico";
    }
    public static override fromData(data: any) {
        if (!data) return null;

        let o: Parametrico = new Parametrico(
            data.id,
            data.codigo,
            data.descripcion,
            data.habilitado,
            data.esSistema,
            data.peso,
            data.icono,
            data.esBoolean
        );
        return o;
    }

    public get iconoClass() {
        return this.icon;
    }

}