
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core'
import { ControlValueAccessor } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser'
import { commonAnimations } from 'src/app/common-animations'

import Cropper from 'cropperjs'
import { MessagesService } from '../../service/messages-data-service.service'
import { TranslateService } from '../../service/translate.service'

@Component({
    selector: 'profile-badge',
    templateUrl: 'profile-badge.component.html',
    styleUrls: ['profile-badge.component.scss'],
    animations: [commonAnimations],
})
export class ProfileBadgeComponent implements OnInit, ControlValueAccessor {
    crop: boolean = false
    croppedImage: string

    @ViewChild('inputFile', { static: false }) inputFile: ElementRef
    scale = 1

    private _type: string = 'landscape'
    public get type(): string {
        return this._type
    }
    @Input()
    public set type(v: string) {
        this._type = v
        if (this.type == 'landscape') {
            this.ratio = 16 / 9
        } else if (this.type == 'portrait') {
            this.ratio = 4 / 3
        }
    }

    public openInput() {
        this.inputFile?.nativeElement && this.inputFile.nativeElement.click()
    }

    public ratio: number = 16 / 9
    public imageElement: ElementRef
    imageSource: any
    cropper: Cropper
    imageDestination: Blob
    @ViewChild('image')
    set image(content: ElementRef) {
        if (content) {
            this.imageElement = content
        }
    }

    constructor(private translateService: TranslateService, private sanitizer: DomSanitizer, private messagesService: MessagesService) {
        var sub = this.translateService.upateData.subscribe((r) => {
            if (!this.defaultImage) {
                this.defaultImage = this.translateService.get('DEFAULT_PROFILE')
                sub.unsubscribe()
            }
        })
    }

    @Input()
    public selected: boolean = false

    public onLoad(e, u: string) {
        this._url = u
    }

    private _url: string
    public get url(): string {
        return this._url
    }
    @Input()
    public set url(v: string) {
        if (v && v != this._url) {
            this._url = v
        }
    }

    @Input()
    public maxSize: number = 250000
    @Input()
    public class: string = ''

    @Input()
    public seleccionable: boolean = false
    public dragging: boolean = false;
    public fileUpload: File

    @Output()
    public onFileSelected: EventEmitter<File> = new EventEmitter<File>()
    @Input()
    public defaultImage: string = 'assets/images/default_image.png'
    private safeUrl: string
    @Input()
    public readonly: boolean = false
    ngOnInit() {}

    @ViewChild('file', { static: true }) public file: ElementRef
    private onChangeCallback: (_: any) => void = () => {}
    private onTouchedCallback: (_: any) => void = () => {}
    writeValue(obj: any): void {
        this.fileUpload = obj
    }
    registerOnChange(fn: any): void {
        this.onChangeCallback = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn
    }
    setDisabledState?(isDisabled: boolean): void {
        this.readonly = isDisabled
    }

    public get profilePic() {
        return this.foto ? this.foto : this.defaultImage
    }
    public get foto() {
        return this.safeUrl ? this.safeUrl : this.url
    }
    handleFileInput(event, file?) {
        this.fileUpload = file || event.target.files.item(0)
        this.imageSource = null;
        this.crop = true

        const reader = new FileReader()

        reader.onload = (e: any) => {
            this.imageSource = e.target.result
        }
        reader.readAsDataURL(this.fileUpload)
    }

    displayCropper() {
        if(this.imageElement?.nativeElement?.cropper)
        this.imageElement.nativeElement.cropper = null;
            this.cropper = new Cropper(this.imageElement.nativeElement, {
                zoomable: true,
                scalable: true,
                aspectRatio: this.ratio,
                crop: () => {
                    const canvas = this.cropper.getCroppedCanvas()
                    canvas.toBlob((t) => {
                        this.fileUpload = new File([t], 'imagen')
                    })
                },
            })
        
    }

    confirmar() {
        if (this.fileUpload.size > this.maxSize) {
            this.messagesService.error('El tamaño máximo es 250KB')
            this.fileUpload = null
            return
        }

        this.onFileSelected.emit(this.fileUpload)
        this.onChangeCallback(this.fileUpload)
        this.crop = false
    }

    cancelar() {
        this.crop = false
    }
    @HostListener('dragover', ['$event']) dragOver(event: any) {
        event.preventDefault();
        event.stopPropagation();
        if(this.readonly) return
        this.dragging=true;
    }
    // Dragleave Event
    @HostListener('dragleave', ['$event']) public dragLeave(event: any) {
        event.preventDefault();
        event.stopPropagation();
        if(this.readonly) return
        this.dragging= false;
    }
    // Drop Event
    @HostListener('drop', ['$event']) public drop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        if(this.readonly) return;
        this.imageSource = event?.dataTransfer?.files[0] || null;
        this.handleFileInput({target:{files:[this.imageSource]}},this.imageSource);
        this.dragging = false;
    }
}
