import { FileData } from "src/app/model/FileData";



export class ProfilePic {
    private _picPathVersion: string;
    public static NUEVO: ProfilePic = new ProfilePic(undefined, "assets/images/plus.png")
    constructor(

        public id?: number,
        public picPath: string = 'assets/images/default_image.png',
        public version?: number,
        public previewPicPath?: string,
    ) {
        this._picPathVersion = this.picPath;// + (this.version ? ("?" + this.version) : "");
    }



    public get picPathVersion(): string {
        return this._picPathVersion;
    }
    public set picPathVersion(val: string) {
        this._picPathVersion = val;
    }

    public static fromData(data: any): ProfilePic  | undefined{
        if (!data) return undefined;
        let o: ProfilePic = new ProfilePic(
            data.id,
            data.picPath,
            data.version,
            data.previewPicPath);
        return o;

    }
    public static fromFileData(data: FileData): ProfilePic  | undefined{
        if (!data) return undefined;
        let o: ProfilePic = new ProfilePic(
            null,
            data.url,
            data.version,
            data.url);
        return o;

    }

}
