import { Component, forwardRef, Input, OnInit } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
    selector: 'layout-picker',
    templateUrl: './layout-picker.component.html',
    styleUrls: ['./layout-picker.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LayoutPickerComponent),
            multi: true,
        },
    ],
})
export class LayoutPickerComponent implements OnInit, ControlValueAccessor {
    private _layoutElegido: string = 'L'
    @Input()
    public readonly: boolean = false
    public get layoutElegido(): string {
        return this._layoutElegido
    }
    @Input()
    public set layoutElegido(v: string) {
        this._layoutElegido = v
        this.onChangeCallback(this._layoutElegido)
    }
    constructor() {}
    writeValue(obj: any): void {
        if (obj != this.layoutElegido) this.layoutElegido = obj
    }
    registerOnChange(fn: any): void {
        this.onChangeCallback = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn
    }
    setDisabledState?(isDisabled: boolean): void {
        this.readonly = isDisabled
    }
    private onChangeCallback: (_: any) => void = () => {}
    private onTouchedCallback: (_: any) => void = () => {}

    ngOnInit() {}
}
