
import { Descriptivo } from 'src/app/common/model/Descriptivo';


export class Emprendedor  extends Descriptivo{
    constructor(
		 id ?: number, 
		public nombres ?: string, 
		public apellidos ?: string, 
		public tipoDocumento ?: Descriptivo, 
		public numeroDocumento ?: string, 
		public linkedin ?: string, 
		public usuario ?: Descriptivo,
		public tieneEmprendimientos?: boolean 

    ) {
		super(id+"",nombres+""+apellidos,id)
    }
    public static override fromData(data: any): Emprendedor {
        if (!data) return null;
        const o: Emprendedor = new Emprendedor(
			data.id, 
			data.nombres, 
			data.apellidos, 
			Descriptivo.fromData(data.tipoDocumento), 
			data.numeroDocumento, 
			data.linkedin, 
			Descriptivo.fromData(data.usuario), 
			data.tieneEmprendimientos

        );

        return o;

    }

}