import { ToastModule } from 'primeng/toast';
import { Component, isStandalone, OnInit } from '@angular/core';
import { MessagesService } from '../../service/messages-data-service.service';



@Component({
	selector: 'messages',
	templateUrl: 'messages.component.html',
	styleUrls: ["messages.component.less"]
})

export class MessagesComponent implements OnInit {
	constructor(private messagesService: MessagesService) {
	}
	ngOnInit() {
		this.messagesService.mService.messageObserver.subscribe(a=>{
			console.log(a);
		})
	}


}