import { LoadingService } from './loading-data-service.service';
import { catchError } from 'rxjs/operators';
import { GlobalInjector } from './../GlobalInjector';
import { MessagesService } from './messages-data-service.service';
import { TranslateService } from './translate.service';
import { Usuario } from 'src/app/common/model/Usuario';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import * as auth from 'firebase/auth';
import { User } from 'firebase/auth';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    protected http: HttpClient;
    public token: string | null = '';
    public interval: any = null;
    public intentos = 0;
    private static _readyOb: BehaviorSubject<boolean> = new BehaviorSubject(
        false
    );
    public static AUTENTICANDO: string = 'IP';
    public static LOGUEADO: string = 'L';
    public static NO_LOGUEADO: string = 'N';
    public static curUser: Usuario;
    public static autenticateState: BehaviorSubject<string>;
    private static userBS: BehaviorSubject<Usuario> = new BehaviorSubject(
        AuthService.curUser
    );
    private static _onLogout: BehaviorSubject<boolean> = new BehaviorSubject(
        false
    );
    private static _onLogin: BehaviorSubject<Usuario> = new BehaviorSubject(
        undefined
    );
    public get onLogout() {
        return AuthService._onLogout;
    }
    private _readyStatus: boolean = false;
    public get onLogin() {
        return AuthService._onLogin;
    }
    public get readyOb() {
        return AuthService._readyOb;
    }
    constructor(
        private router: Router,
        protected messages: MessagesService,
        public afAuth: AngularFireAuth,
        protected transService: TranslateService,
        protected loadingService:LoadingService,
    ) {
        this.http = GlobalInjector.InjectorInstance.get<HttpClient>(HttpClient);
        AuthService.autenticateState = new BehaviorSubject<string>(
            AuthService.AUTENTICANDO
        );
        this._readyStatus = true;
        this.onLogin.next(this.usuario);
        this.readyOb.next(this._readyStatus);
    }
    getApiURL() {
        return environment.apiUrl;
    }
    public getAutenticateState() {
        return AuthService.autenticateState;
    }

    public get usuario(): Usuario {
        return AuthService.curUser;
    }
    public set usuario(v: Usuario) {
        AuthService.curUser = v;
        AuthService.userBS.next(v);

        this.username = v?.username || '';
    }

    public set renewToken(val: string | null) {
        if (val) {
            localStorage.setItem(environment.tokenKey + '_renewToken', val);
        } else {
            localStorage.removeItem(environment.tokenKey + '_renewToken');
        }
    }
    public get renewToken() {
        return localStorage.getItem(environment.tokenKey + '_renewToken') || '';
    }

    public set username(val: string | null) {
        if (val) {
            localStorage.setItem(environment.tokenKey + '_username', val);
        } else {
            localStorage.removeItem(environment.tokenKey + '_username');
        }
    }
    public get username() {
        return localStorage.getItem(environment.tokenKey + '_username') || '';
    }

    public set push(val: string | null) {
        if (val) {
            localStorage.setItem(environment.tokenKey + '_push', val);
        } else {
            localStorage.removeItem(environment.tokenKey + '_push');
        }
    }
    public get push() {
        return localStorage.getItem(environment.tokenKey + '_push') || '';
    }
    getUser(): Observable<Usuario> {
        return AuthService.userBS;
    }

    protected handleOk = (response: any): string => {
        let res = response;
        return res.mensaje;
    };
    protected handleError(error: any): Promise<any> {
        return Promise.reject(error.error || error);
    }
    recuperarPass(email: string): Promise<any> {
        return lastValueFrom(
            this.http.post(this.getApiURL() + 'login/recuperar-password', email)
        )
            .then((r: any) => {
                return Promise.resolve(r ? Usuario.fromData(r) : null);
            })
            .catch(this.messages.errorHandler);
    }
    GoogleAuth(esRegistro:boolean = false, perfil:"INV"|"EMP" = "INV") {
        return this.authLogin(new auth.GoogleAuthProvider(),esRegistro? this.registrar : this.loguear, perfil)
    }
    registrarUsuario = async(usuario:Usuario, loadingService:LoadingService = this.loadingService):Promise<any> =>{
        loadingService.addLoadingCount();
        return lastValueFrom(this.http.put(this.getApiURL() + 'login/registrarUsuario',usuario.json)).then(r=>{
            return r;
        }).finally(()=>{
            loadingService.susLoadingCount()
        });
    }
    registrar = async(credenciales, token:string, perfil:"INV"|"EMP" = "INV"):Promise<Usuario>=>{
        const user: User = credenciales.user;
        const u = new Usuario(null,user.email,
            user.displayName,null,true,false,user.email,null,user.phoneNumber,null,true,null,null);
            u.uid = credenciales.user.uid;
            u.token = token;
            u.perfil=perfil;
            return lastValueFrom(this.http.put(this.getApiURL() + 'login/registrar',{token,usuario:u.json})).then((u:any)=>{
                this.usuario = Usuario.fromData(u.usuario);
                return this.usuario;
            })   
    }
    loguear = async(credenciales, token:string, perfil:"INV"|"EMP"="INV"):Promise<Usuario>=>{
        return this.updateBackendUser(credenciales.user.uid,token).then(u=>{
            this.usuario = u;
            AuthService.autenticateState.next(AuthService.LOGUEADO)
            return this.usuario;
        })
    }
    authLogin(provider: any, callback:(credentials:any,token:string, perfil:"INV"|"EMP")=>Promise<Usuario>,perfil:"INV"|"EMP"="INV") {
        return this.afAuth
            .signInWithPopup(provider)
            .then(async(result) => {
                const token =await result.user.getIdToken();
                return callback(result,token,perfil).then(u=>{
                    this.usuario = u;
                    this.router.navigate(["/home"])
                });
            })
    }
    updateBackendUser(uid:string,token:string):Promise<Usuario>{
        return lastValueFrom(this.http.post(this.getApiURL() + 'login/get-user-app',{uid,idToken:token})).then((r:any)=>{
            this.token = r.token;
            return Usuario.fromData(r.usuario);
        })
    }
    setUserData(user: any) {
        const userData: Usuario = Usuario.fromData({
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            emailVerified: user.emailVerified,
        });
    }
    async login(user: string, password: string): Promise<Usuario | undefined> {
        let $this = this;
        return lastValueFrom(
            this.http.post(this.getApiURL() + 'login', {
                username: user,
                password: password,
            })
        ).then((r: any) => {
            if (r) {
                $this.usuario = Usuario.fromData(r.usuario);
                $this.token = r.token;
                if (r.renewToken) {
                    $this.renewToken = r.renewToken;
                }
                this.setRenew(r);
                this.getAutenticateState().next(AuthService.LOGUEADO);
                this.onLogin.next(this.usuario);
                return Promise.resolve($this.usuario);
            } else {
                this.getAutenticateState().next(AuthService.NO_LOGUEADO);
            }

            return Promise.resolve(undefined);
        });
    }

    async logout(): Promise<any> {
        this.token = null;
        this.push = null;
        this.usuario = undefined;
        this.username = null;
        AuthService.curUser = undefined;
        this.renewToken = null;
        if (this.interval) {
            clearInterval(this.interval);
        }
        this.onLogout.next(true);
        return Promise.resolve(true);
    }

    private setRenew(r: any) {
        if (this.interval) clearInterval(this.interval);
        this.interval = setInterval(
            () => {
                this.refreshToken();
            },
            r.renewTime && r.renewTime >= 900000 ? r.renewTime - 60000 : 840000
        );
    }
    public refreshToken(): Observable<any> {
        let $this = this;
        let data: BehaviorSubject<string> = new BehaviorSubject<string>('');
        if (this.intentos == 4 || !this.renewToken) {
            this.intentos = 0;
            this.logout().then((r: any) => {
                this.router.navigate(['login']);
                data.complete();
            });
            return data;
        }
        this.intentos++;
        lastValueFrom(
            this.http.post(this.getApiURL() + 'reauthenticate', {
                username: this.username,
                renewToken: this.renewToken,
            })
        )
            .then((r: any) => {
                $this.usuario = Usuario.fromData(r.usuario);
                $this.token = r.token;
                this.intentos = 0;
                if (r.renewToken) {
                    $this.renewToken = r.renewToken;
                }
                if (!this.interval) {
                    this.setRenew(r);
                }
                this.getAutenticateState().next(AuthService.LOGUEADO);
                this.onLogin.next(this.usuario);
                data.next(r);
            })
            .catch((r: any) => {
                this.token = null;
                this.push = null;
                this.usuario = null;
                this.logout().then((r: any) => {
                    this.router.navigate(['login']);
                    data.complete();
                });
            });
        return data;
    }

    get isLoggedIn(): boolean {
        return this.usuario != undefined && this.token != undefined;
    }
    get getCurrentUser(): Usuario {
        return this.usuario;
    }
    get esAdministrador() {
        return (
            this.getCurrentUser &&
            this.getCurrentUser.roles.some(
                (r: any) => r.codigo === 'ROLE_ADMIN'
            )
        );
    }
    get esJefeAdministracion() {
        return (
            this.getCurrentUser &&
            (this.esAdministrador ||
                this.getCurrentUser.roles.some(
                    (r: any) => r.codigo === 'ROLE_JEFE_ADMIN'
                ))
        );
    }
    public tieneRol(rol: string) {
        return this.getCurrentUser.tieneRol(['ROLE_ADMIN', rol]);
    }
    get esSoloVisualizador() {
        return this.usuario.esSoloVisualizador();
    }

    getUserFullName(): string {
        let user = this.getCurrentUser ? this.getCurrentUser : null;
        return user ? user.nombre : 'SIN INFORMAR';
    }
}
