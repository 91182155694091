// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  timezone: '-0300',
  apiUrl: "https://vsg-invest-back.costazini.com.ar/vsg-invest-backend/",
  tokenKey: "vsg-dev",
  googleApiKey: "6Lf1TqsUAAAAACnWY1ryBv7ortfHjJERCLUNF5Ei",
  logo: "assets/images/logo-blanco.png",
  firebase: {
    apiKey: "AIzaSyASTeWyg8BnVpkJX4J3d5hhsT4dn6ZqEyU",
    authDomain: "vsg-invest-test.firebaseapp.com",
    projectId: "vsg-invest-test",
    storageBucket: "vsg-invest-test.appspot.com",
    messagingSenderId: "911337792645",
    appId: "1:911337792645:web:feaa9f95f5d393d6d2f5ec",
    measurementId: "G-059RB3HFFD"
  },
  recaptchaKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  isOverlay: false,
  darkMenu: false,
  mainLogo:'assets/images/logo.png'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
