import { ToastModule } from 'primeng/toast';
import { NgModule } from '@angular/core';
import { MessagesComponent } from 'src/app/common/components/messages/messages.component';
import { MessagesService } from '../../service/messages-data-service.service';
import { MessageService } from 'primeng/api';
const components = [MessagesComponent]
@NgModule({
    imports: [
        ToastModule
    ],
    declarations: [...components],
    exports: [...components],
})
export class MessagesModule { }