
import { Emprendedor } from './../../pages/emprendedor/model/Emprendedor';
import { Inversor } from './../../pages/inversor/model/Inversor';
import { ConfiguracionUsuario } from './ConfiguracionUsuario';
import { Descriptivo } from './Descriptivo';
import { ProfilePic } from './ProfilePic';
import { Rol } from './Rol';

export class Usuario extends Descriptivo {

    public static MODEL_NAME = "usuario";
    public uid;
    public token;
    public password;
    public confPassword
    constructor(
        id?: number,
        public username?: string,
        public nombre?: string,
        public apellido?: string,
        public activo: boolean = true,
        public bloqueado: boolean = false,
        public email?: string,
        public profilePic: ProfilePic = new ProfilePic(undefined, 'assets/images/default_profile.png'),
        public telefono?: string,
        public roles: Rol[] = [],
        public esVerificado: boolean = false,
        public lastModifiedBy?: string ,
        public lastModifiedDate?: Date ,        
        public configuracion: ConfiguracionUsuario = new ConfiguracionUsuario(),
        public perfil:"INV"|"EMP" = "INV",
        public inversor?:Inversor,
        public emprendedor?:Emprendedor
    ) {
        super(id + "", username, id);
        this.telefono = telefono;
        this.roles = roles;
        this.esVerificado = esVerificado;
    }
    entityName(): string {
        return Usuario.MODEL_NAME;
    }
    public static override fromData(data: any): Usuario  {
        if (!data) return null;
        let u: Usuario = new Usuario(data.id,
            data.username,
            data.nombre,
            data.apellido,
            data.activo,
            data.bloqueado,
            data.email,
            data.profilePic ? ProfilePic.fromData(data.profilePic) : new ProfilePic(),
            data.telefono,
            data.roles ? data.roles.map(Rol.fromData) : [],
            data.esVerificado,
            data.lastModifiedBy,
            data.lastModifiedDate? new Date(data.lastModifiedDate) : undefined,
            ConfiguracionUsuario.fromData(data.configuracion),
            data.perfil,
            data.inversor? Inversor.fromData(data.inversor): null,
            data.emprendedor? Emprendedor.fromData(data.emprendedor): null,
            )
        return u;
    }

    esAdmin() {
        return this.roles.some(r => r.codigo == Rol.ADMIN_CODE);
    }
    tieneRol(roles: string[]) {
        for (let rol = 0; rol < roles.length; rol++)
            if (this.roles.filter(r => r.codigo === roles[rol]).length > 0)
                return true;

        return false;
    }
    tieneRolOAdmin(roles: string[]){
        return this.esAdmin() || this.tieneRol(roles);
    }

    esSoloVisualizador() {
        return this.roles.length === 1 && this.roles.some(r => r.codigo == Rol.VISUALIZADOR_CODE);
    }

    get nombreCompleto(): string {
        return this.descripcion;
    }



    public get imagen() {
        return this.profilePic;
    }
    public get imagenUrl() {
        return this.profilePic?.picPath || 'assets/default_user.png';
    }

    public get json() {
        var ob: any = Object.assign({}, this);
        return ob;
    }

}
