import { ColorPickerModule } from 'primeng/colorpicker';
import { Component, forwardRef, Input, OnInit } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { CommonComponentsModule } from 'src/app/common-components.module';

@Component({
    selector: 'color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ColorPickerComponent),
            multi: true,
        },
    ],
})
export class ColorPickerComponent implements OnInit, ControlValueAccessor {
    public historial: string[] = []
    // public selectedColor: string = "#fff";
    private _color: string = '#ff0000'
    @Input()
    public readonly: boolean = false
    public get color(): string {
        return this._color
    }
    public set color(v: string) {
        this._color = v ? v : '#ff0000'
        this.onChangeCallback(this._color)
    }

    public agregarHistorial(value) {
        if (this.historial.indexOf(value) >= 0) return
        this.historial.unshift(value)
        if (this.historial.length > 12) {
            this.historial = this.historial.slice(0, 12)
        }
        localStorage.setItem('historial', JSON.stringify(this.historial))
    }
    private onChangeCallback: (_: any) => void = () => {}
    private onTouchedCallback: (_: any) => void = () => {}
    public disabled: boolean = false
    constructor() {}

    writeValue(obj: any): void {
        this.color = obj
    }
    registerOnChange(fn: any): void {
        this.onChangeCallback = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }

    ngOnInit() {
        this.historial = JSON.parse(localStorage.getItem('historial')) || []
    }
}
