import { Pipe, PipeTransform } from '@angular/core';
import { AccionesTabla } from './../model/AccionesTabla';
@Pipe({
    name: 'visibles',
    pure: false
})
export class AccionesVisiblesPipe implements PipeTransform {
    constructor() { }
    transform(acciones: AccionesTabla[], item, maxCount= 5, displayAll = false): any {
        let result = acciones? acciones.filter(a => a && a.esVisible(item)) : [];
        return maxCount && !displayAll? result.slice(0,maxCount > result.length? result.length : maxCount) : result;
    }
}