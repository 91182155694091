import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FilterSummary } from './FilterSummary';

export class Filtro {
    
    constructor(public name: string, public filterMap, page: number = 0, size?: number, sortField: string = 'id', sortOrder: number = 1, guardable: boolean = false, public localSearch: boolean = false) {
        
        this._page = page;
        this._size = size;
        this._sortField = sortField;
        this._sortOrder = sortOrder;
        this._guardable = guardable;        
        this._currValue = JSON.stringify(this.json);
        this.summary = FilterSummary.parseForm(this, this.filterMap);
        this.saveValue = new BehaviorSubject(this);
        this.valueChange = new BehaviorSubject(this);
        this.subscribeChanges();
        this.ready = true;
    }
    public valueChange: BehaviorSubject<any> = new BehaviorSubject(null);
    public dataChange: BehaviorSubject<any> = new BehaviorSubject(null);
    public saveValue: BehaviorSubject<any> = new BehaviorSubject(null);
    public sub: Subscription;
    public isReady: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _currValue: string = ""
    private _ready: boolean;
    public get ready(): boolean {
        return this._ready;
    }
    public set ready(v: boolean) {
        this._ready = v;
        this.isReady.next(v);
    }
    private _page: number;

    private _guardable: boolean = true;

    private _layout: string = "L";
    public get layout(): string {
        return this._layout;
    }
    public set layout(v: string) {
            this._layout = v;
            this.update(true);     
           
        
    }
    public setMultiple(data: any, update: boolean = true) {
        var updated = false;
        for (const property in data) {
            if(!updated) updated = this["_" + property] != data[property];
 
            this["_" + property] = data[property]

        }
        if (updated) this.update(true);
    }
    public get guardable(): boolean {
        return this._guardable;
    }

    public forceUpdate() {
        this._currValue = JSON.stringify(this.json);
        this.dataChange.next(this);
    }
    public set guardable(v: boolean) {
        this._guardable = v;
        if (this._guardable && !this.sub) {
            this.subscribeChanges();
        }
    }



    private _habilitados: boolean;
    public get habilitados(): boolean {
        return this._habilitados;
    }
    public set habilitados(v: boolean) {
        if (v == this._habilitados) return;
        this._habilitados = v;
        this.update(true);
    }


    public _idioma: string;
    public get idioma(): string {
        return this._idioma;
    }
    public set idioma(v: string) {
        if (v != this._idioma) {
            this._idioma = v;
            this.update(true)
        }

    }



    private _sortOrder: number;
    public get sortOrder(): number {
        return this._sortOrder;
    }
    public set sortOrder(v: number) {
        if (v == this._sortOrder) return;
        this._sortOrder = v;
        this.update(true);
    }

    private _sortField: string;
    public get sortField(): string {
        return this._sortField;
    }
    public set sortField(v: string) {
        if (v == this._sortField) return;
        this._sortField = v;
        this.update(true);
    }

    private _size: number;
    public get size(): number {
        return this._size;
    }
    public set size(v: number) {
        if (v == this._size) return;
        this._size = v;
        this.update(true);
    }

    public get page(): number {
        return this._page;
    }
    public set page(v: number) {
        if (v == this._page) return;
        this._page = v;
        this.update(true, false);
    }
    public get paginationPage() {
        return this.size * this.page + 1;
    }
    protected onCleanCallback = (f) => {

    };
    public onClean(f: (f) => void) {
        this.onCleanCallback = f;
    }
    protected _searchStr: string;
    public count: number = 0;
    public summary: FilterSummary[] = [];
    public update(triggerChange: boolean = true, resetPage: boolean = false) {
        const prev = [...this.summary];
        this.summary = FilterSummary.parseForm(this, this.filterMap);
        if (resetPage || JSON.stringify(prev) != JSON.stringify(this.summary))
            this._page = 0;
        //this.saveValue.next(this);
        if (triggerChange) {
            this.dataChange.next(this);

        }

    }

    public get searchStr(): string {
        return this._searchStr;
    }
    public set searchStr(v: string) {
        this._searchStr = v;
        if (!this.localSearch)
        this.update(true);
    }

    public valid() {
        return true;
    }

    public setPage(page, size, sort, sortOrder, update: boolean = false) {
        this._page = page;
        this._size = size;
        this._sortField = sort;
        this._sortOrder = sortOrder;
        this.update(update, false);
    }
  
    public subscribeChanges() {       
        if (this.sub) this.sub.unsubscribe();
        this.sub = this.dataChange.pipe(filter(v => v != undefined && this._currValue != JSON.stringify(this.json))).subscribe(v => {
            this._currValue = JSON.stringify(this.json);
            this.valueChange.next(this);
        })
    }
    public clean(update: boolean = true) {
        this._searchStr = "";
        this._page = 0;
        this._size = 30;
        this._sortField = "id";
        this._sortOrder = 1;
        this._idioma = "ES";
        this.onCleanCallback(this);
        this.update(update);
    };
    public apply(d: any[]) {
        const searchLike = this.searchStr ? this.searchStr.toUpperCase().split(" ") : null;
        return !searchLike ? d : d.filter(value => 
            !value['searchStr'] || searchLike.every(v =>  value['searchStr'].toUpperCase().includes(v.toUpperCase())))
    }

    public replacer(key: string, value) {
        if (key == "stateObs" || key == "isReady" || key == "onCleanCallback" 
        || key == "ready" || key == "_currValue" 
        || key == "dataChange" || key == "valueChange" 
        || key == "saveValue" || key == "sub" 
        || key == 'summary' || key == 'filterMap' || key == 'name' || key == 'count') return undefined;
        else return value
    }
    public patchValue(v: any, update: boolean = true, resetPage: boolean = true) {
        this._page = v.page ? v.page : 0;
        this._size = v.size ? v.size : 3000;
        this._sortField = v.sortField;
        this._sortOrder = v.sortOrder;
        this._idioma = v.idioma;
        this._layout = v.layout ? v.layout : 'L';
        // this._guardable = v.guardable;
        this.update(update,   !v.page && resetPage);
    };

    public get json(): any {
        return JSON.parse(JSON.stringify(this, this.replacer).replace(/_/g, ""));
    }
    public hasFilters(){
        return this.summary?.length > 0;
    }
    public unsuscribe(){
        if(this.valueChange) this.valueChange.observers =[];
        if(this.dataChange) this.dataChange.observers =[];
        this.sub = null;
        this.subscribeChanges();
    }

    clonar() {
        const f = new Filtro(null,null);
         f.patchValue(this,false,false)
         return f;
    }

}