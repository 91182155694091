import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-download-file',
  templateUrl: './download-file.component.html',
  styleUrls: ['./download-file.component.less']
})
export class DownloadFileComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }
  public estado = 'b';
  private _tipo;
  private _token;
  public retryLink() {
    if (this._token && this._tipo) {

      switch (this._tipo) {
       /* case "C":
          this.comprobanteService.descargarToken(this._token).then(c => {
            this.estado = "d";
          }).catch(e => {
            this.estado = "e";
          });
          break
        default:
          this.estado = "e";
          break;*/
      }

    } else {
      this.estado = 'e';
    }
  }
  ngOnInit() {
    this.route.params.pipe(first()).subscribe(params => {
      this._token = params["token"];
      this._tipo = params["tipo"];
      this.retryLink()
    })

  }

}
