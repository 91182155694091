<div class="p-inputgroup" *ngIf="importeForm" [ngClass]="{ readonly: readonly }" tabindex="1">
    <span class="p-float-label" *ngIf="moneda">
       
        <i class="warning" *ngIf="importeForm?.value < minValue">warning </i>
        <p-inputNumber
            [readonly]="readonly"
            [formControl]="importeForm"
            tabindex="1"
            #input
            pTooltip="{{ tooltip ? tooltip : (label | translate) }}"
            name="importe"
            [min]="minValue"
            [max]="maxValue"
            [styleClass]="'span-dinero-editable'"
            [required]="required"
            [locale]="moneda.locale || 'es-ES'"
            (onFocus)="onFocus($event)"
            (onInput)="onInput($event)"
            mode="currency"
            currencyDisplay="symbol"
            currency="{{ moneda?.codigo || 'USD' }}"
           
            
        ></p-inputNumber>

        <label>{{ label | translate }}</label>
    </span>

    <small class="p-error block"> {{ getErrorMessage(importeForm) }}</small>
</div>
