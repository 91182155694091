<div class="contactos grid align-items-center grid grid-nogutter" *ngIf="data?.telefono || data?.email">
    <div class="{{ containerClass + ' overflow' }}">
        <span *ngIf="data?.nombre" class="data" pTooltip="{{ data.nombre }}"><i class="pi pi-user info"></i>{{ data?.nombre }}</span>
    </div>

    <div class="{{ containerClass + ' overflow' }}">
        <span *ngIf="data?.telefono" class="data" pTooltip="{{ data.telefono }}" (click)="call($event)"
            ><i class="pi pi-phone info"></i>{{ data?.telefono }}</span
        >
    </div>
    <div class="{{ containerClass + ' overflow' }}">
        <span *ngIf="data?.email" class="data" pTooltip="{{ data.email }}" (click)="sendEmail($event)"><i class="fa fa-at info"></i>{{ data?.email }}</span>
    </div>
</div>
