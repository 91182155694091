


export class FilaProcesoCargaMasiva  {
    constructor(
		public indice ?: number, 
		public contenido ?: string, 
		public mensajeError ?: string, 

    ) {
    }
    public static fromData(data: any): FilaProcesoCargaMasiva {
        if (!data) return null;
        const o: FilaProcesoCargaMasiva = new FilaProcesoCargaMasiva(
			data.indice, 
			data.contenido, 
			data.mensajeError, 

        );

        return o;

    }

}