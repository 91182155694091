<div class="layout-menu-profile">
    <button class="p-link" (click)="editarPerfil()" pTooltip="Profile" [tooltipDisabled]="isTooltipDisabled">
        <img src="{{usuario?.imagenUrl}}" alt="avatar" style="width: 32px; height: 32px;">
        <span>
            <strong>{{usuario?.nombreCompleto}}</strong>
            <small>{{usuario?.username}}</small>
        </span>
        <i class="layout-menu-profile-toggler pi pi-fw" [ngClass]="{'pi-angle-down': menuProfilePosition === 'start' || isHorizontal, 'pi-angle-up': menuProfilePosition === 'end' && !isHorizontal}"></i>
    </button>

</div>
