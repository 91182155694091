import { first } from 'rxjs/operators';
import { AuthService } from './common/service/auth.service';
import { Subscription, Observable, filter } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from './common/service/translate.service';
import { Usuario } from './common/model/Usuario';
import { MessagingService } from './common/service/messages.service';
import { NovedadService } from './common/service/novedades.service';
import { Route, Router } from '@angular/router';
export enum KEY_CODE {
    RIGHT_ARROW = 39,
    LEFT_ARROW = 37,
    PLUS = 107,
    ENTER = 13,
}
export let COLORS = [
    '#003f5c',
    '#2f4b7c',
    '#665191',
    '#a05195',
    '#d45087',
    '#f95d6a',
    '#ff7c43',
    '#ffa600',
    '#ce99ff',
    '#8eaaff',
    '#2db7ff',
    '#00bffb',
    '#00c3dd',
    '#00c3ba',
    '#55c099',
    '#83bb7f'
    
]
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    private userSub: Subscription
    private logoutSub:Subscription
    constructor(private primengConfig: PrimeNGConfig, private authService: AuthService,
        private translateService:TranslateService,
        private router: Router,
        private swUpdate: SwUpdate,
        private novedadesService: NovedadService,    
        private pushMessages: MessagingService,) { }
    ngOnDestroy(): void {
        if (this.userSub) this.userSub.unsubscribe()
    }
    ngOnInit(): void {
        const  windwUrl = window['cambioClave']
        this.primengConfig.ripple = true;
        this.translateService.use(this.getUsersLocale('es'))
        this.translateService.cargarDataCodebase(this.getUsersLocale('es'))
        if (!this.authService.token && windwUrl.indexOf('/public') < 0 && windwUrl.indexOf('/login') < 0) {
            this.authService
                .refreshToken()
               
        }
        let a = null;
        a = this.authService.getAutenticateState().subscribe((r) => {
            if (r == AuthService.LOGUEADO) {
                let p = this.getCurrentUser().subscribe((u) => {
                    if (u != null) {
                        this.pushMessages.requestPermission()
                        this.pushMessages.tokekObs.pipe(filter((t) => t != undefined)).subscribe((t) => {
                            this.novedadesService.updateToken(t)

                            this.pushMessages.getMessages().subscribe((m) => {
                                this.novedadesService.nuevoPush(m)
                            })
                        })

                        if(u.emprendedor!= null && !u.emprendedor.tieneEmprendimientos){
                            this.router.navigate(['emprendimiento/nuevo'])
                        }
                    }
                })
                if(this.logoutSub) this.logoutSub.unsubscribe();
            }
            if (a) a.unsubscribe()
        })
       

        if (this.swUpdate.isEnabled) {
            this.swUpdate.versionUpdates.subscribe((n) => {
                if (confirm('Hay una nueva versión, desea actualizarla?')) {
                    window.location.reload()
                }
            })
        }
    }
    getCurrentUser(): Observable<Usuario> {
        return this.authService.getUser()
    }
    logout() {
        this.clearCredentials().then((r) => {
            this.router.navigate(['login'])
        })
    }

    protected clearCredentials(): Promise<any> {
        return this.authService.logout()
    }
    public getUsersLocale(defaultValue: string): string {
        if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
            return defaultValue
        }
        const wn = window.navigator as any
        let lang = wn.languages ? wn.languages[0] : defaultValue
        lang = lang || wn.language || wn.browserLanguage || wn.userLanguage
        return lang
    }
    

}
