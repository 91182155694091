import { Injectable } from '@angular/core';
import {  lastValueFrom } from 'rxjs';
import { Filtro } from '../model/Filtro';
import { Idioma } from '../model/Idioma';
import { LoadingService } from './loading-data-service.service';
import { ParametricoService } from './parametricos.service';

@Injectable({
    providedIn: 'root',
})
export class IdiomaService extends ParametricoService<Idioma>{
    public baseName(): string {
        return "idioma";
    }
    public parseToEnt(data: any): Idioma {
        return Idioma.fromData(data);
    }
    public newEnt(): Idioma {
        return new Idioma();
    }
    protected override fill(filtro = new Filtro(null,{},0,10000,'peso,descripcion'),customLoading?){
        return this.getAll(filtro, customLoading).then((r) => {
          this._data = this.parse(r);
          this.data.next(r);
          return r;
        });
      }
    override getAll =(filtro: Filtro = new Filtro('dummy', {}), customLoading?: LoadingService): Promise<Idioma[]> => {
        let $this = this;
        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        return lastValueFrom(this.http.post(this.getPublicBaseURL('all'), filtro.json),{defaultValue:[]})
            .then(r => {
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
                return $this.parse(r)
            }).catch((e) => this.handleError(e, customLoading)).finally(()=>{
                if (customLoading) { customLoading.susLoadingCount(); } else this.loadingService.susLoadingCount()
            });
    }


}