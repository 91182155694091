import { AdminAuthGuard } from './AdminAuthGuard';
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { catchError, filter, map, of } from 'rxjs';

@Injectable({
    providedIn:'root'
})
export class EmprendimientoAG extends AdminAuthGuard {

    override canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        const id = Number(route.queryParams["id"]||0)

        return this.auth.getUser().pipe(filter(u => u != undefined), map(u => {
            return this.esVisible(u);
        }), catchError(e => of(false)));
    }
    public override getRoles(): string[] {
        return super.getRoles().concat(['ROLE_EMPRENDEDOR','ROLE_EMPRENDIMIENTO'])
    }
}
