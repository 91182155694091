export class Novedad {

    constructor(
        public alta: Date | null = new Date(),
        public tipo: string,
        public key: string,
        public idEntidad: number) {

    }

    public static fromData(data:any) {
        if (!data) return null;
        return new Novedad(
            data.alta ? new Date(data.alta) : null,
            data.tipo,
            data.key,
            data.idEntidad
        )
    }


}