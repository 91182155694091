<input
    id="color-input"
    pInputText
    [(colorPicker)]="color"
    [style.background]="color"
    tooltipPosition="bottom" pTooltip="{{ 'COLOR' | translate }}"
    placeholder="{{ 'COLOR' | translate }}"
    #picker
    readonly
    [disabled]="readonly"
    [cpPresetColors]="historial"
    [cpSaveClickOutside]="true"
    cpPresetLabel="Historial"
    cpAlphaChannel="disabled"
    (colorPickerSelect)="agregarHistorial($event)"
/>
