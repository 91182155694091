import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[delayRender]'
})
export class DelayRenderDirective {

  constructor(element: ElementRef) {
    element.nativeElement.style.display = "none";
    setTimeout(() => {
      element.nativeElement.style.display = "inherit";
    }, 2000)
  }

}
