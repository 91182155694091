import { AdminAuthGuard } from './AdminAuthGuard';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { catchError, filter, map, of } from 'rxjs';
@Injectable({
    providedIn: "root"
})
export class UsuarioAG extends AdminAuthGuard {
    override canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
       const id = Number(route.queryParams["id"]||0)

        return this.auth.getUser().pipe(filter(u => u != undefined), map(u => {
            return this.esVisible(u) || (!u.esAdmin() || u.id === id);
        }), catchError(e => of(false)));
    }

    public override getRoles(): string[] { return super.getRoles().concat(["ROLE_USER","ROLE_USER", "ROLE_INVERSOR","ROLE_EMPRENDEDOR"]) };



}