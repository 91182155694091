<div class="layout-container" [ngClass]="containerClass">

    <app-topbar></app-topbar>
    <app-sidebar></app-sidebar>

    <div class="layout-content-wrapper">
        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-config></app-config>

    <div *ngIf="layoutService.state.staticMenuMobileActive" class="layout-mask"></div>
    
<p-confirmDialog [key]="'genConf'" [appendTo]="'body'" 
icon="fa fa-question-circle" acceptLabel="Si" rejectLabel="No"></p-confirmDialog>
</div>
