<div class="parametrico-selector">
    <descriptivo-material-selector [options]="data | async" [label]="finalTitle" [limpiable]="limpiable"
        [(ngModel)]="seleccionado" [permiteNuevo]="permiteNuevo" [gestor]=" gestor? gestor : defaultGestor"
        [isLazy]="false" [placeHolder]="placeHolder" [disabled]="disabled" [required]="required" [name]="name"
        [newEnt]="onNew" [appearence]="appearence"
        [default]="default" [readonly]="readonly" (onSelect)="seleccionar($event)" [itemTemplate]="itemTemplate"
        [idioma]="idioma">
    </descriptivo-material-selector>
</div>

<ng-template #defaultGestor let-handler="handler" let-itemEditado="itemEditado">
    <gestor-parametrico [service]="service? service : defaultService" (onGuardar)="handler.onGuardado($event)"
        (onCancelar)="handler.onCancelado()" [goBack]="false" [isModal]="true"
        [title]="('NUEVO' | translate) +  ' ' + finalTitle " [itemEditado]="itemEditado">
    </gestor-parametrico>
</ng-template>
