import { MenuItem } from 'primeng/api';
export class AccionesTabla implements MenuItem {


    public label: string;
    constructor(
        public tooltip: string = "",
        public icon: string = "fa fa-cog",
        public styleClass: string = "accion-tabla",
        public command: (r: any | any[], event?) => void = function (r) {
            console.log(r);
        },
        public esVisible: (r: any | any[]) => boolean = function (r) {
            return true;
        },
        public styleClassFn?: (r: any | any[]) => string,

    ) {
        this.label = tooltip;


    }
}