import { NgModule } from '@angular/core'
import { AccordionModule } from 'primeng/accordion'
import { ConfirmationService, MessageService } from 'primeng/api'
import { AutoCompleteModule } from 'primeng/autocomplete'
import { BadgeModule } from 'primeng/badge'
import { CalendarModule } from 'primeng/calendar'
import { CardModule } from 'primeng/card'
import { CarouselModule } from 'primeng/carousel'
import { ChartModule } from 'primeng/chart'
import { CheckboxModule } from 'primeng/checkbox'
import { ChipsModule } from 'primeng/chips'
import { ColorPickerModule } from 'primeng/colorpicker'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { ContextMenuModule } from 'primeng/contextmenu'
import { DataViewModule } from 'primeng/dataview'
import { DialogModule } from 'primeng/dialog'
import { DragDropModule } from 'primeng/dragdrop'
import { DropdownModule } from 'primeng/dropdown'
import { EditorModule } from 'primeng/editor'
import { FileUploadModule } from 'primeng/fileupload'
import { GalleriaModule } from 'primeng/galleria'
import { InputMaskModule } from 'primeng/inputmask'
import { InputNumberModule } from 'primeng/inputnumber'
import { InputSwitchModule } from 'primeng/inputswitch'
import { ListboxModule } from 'primeng/listbox'
import { MenuModule } from 'primeng/menu'
import { MenubarModule } from 'primeng/menubar'
import { MessageModule } from 'primeng/message'
import { MessagesModule } from 'primeng/messages'
import { MultiSelectModule } from 'primeng/multiselect'
import { OverlayPanelModule } from 'primeng/overlaypanel'
import { PanelModule } from 'primeng/panel'
import { PasswordModule } from 'primeng/password'
import { PickListModule } from 'primeng/picklist'
import { RadioButtonModule } from 'primeng/radiobutton'
import { RippleModule } from 'primeng/ripple'
import { ScrollPanelModule } from 'primeng/scrollpanel'
import { SelectButtonModule } from 'primeng/selectbutton'
import { SidebarModule } from 'primeng/sidebar'
import { SlideMenuModule } from 'primeng/slidemenu'
import { SliderModule } from 'primeng/slider'
import { SpinnerModule } from 'primeng/spinner'
import { TableModule } from 'primeng/table'
import { TabViewModule } from 'primeng/tabview'
import { ToastModule } from 'primeng/toast'
import { ToggleButtonModule } from 'primeng/togglebutton'
import { TooltipModule } from 'primeng/tooltip'
import { ChipModule } from 'primeng/chip';
import { MegaMenuModule } from 'primeng/megamenu'
import { InputTextModule } from 'primeng/inputtext'
import { InputTextareaModule } from 'primeng/inputtextarea';

@NgModule({
    exports: [
        CheckboxModule,
        ConfirmDialogModule,
        DialogModule,
        CalendarModule,
        AccordionModule,
        PanelModule,
        TableModule,
        RadioButtonModule,
        MultiSelectModule,
        AutoCompleteModule,
        TabViewModule,
        ToggleButtonModule,
        PickListModule,
        ChipsModule,
        TooltipModule,
        FileUploadModule,
        SidebarModule,
        InputMaskModule,
        MenubarModule,
        ScrollPanelModule,
        MenuModule,
        ContextMenuModule,
        PasswordModule,
        EditorModule,
        MessagesModule,
        MessageModule,
        InputTextModule,
        ToastModule,
        CarouselModule,
        InputSwitchModule,
        DragDropModule,
        OverlayPanelModule,
        DataViewModule,
        SpinnerModule,
        DropdownModule,
        ChartModule,
        CardModule,
        SlideMenuModule,
        SelectButtonModule,
        GalleriaModule,
        SliderModule,
        InputNumberModule,
        ColorPickerModule,
        BadgeModule,
        ListboxModule,
        RippleModule,
        ChipModule,
        PasswordModule,
        MegaMenuModule,
        InputTextareaModule
    ],
    providers: [ConfirmationService, MessageService],
})
export class PrimeNgModule {}
