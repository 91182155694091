import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Filtro } from './../model/Filtro';
import { Parametrico } from './../model/Parametrico';
import { LoadingService } from './loading-data-service.service';
import { BufferedDescriptivoService } from './BufferedDescriptivoService';
import { MessagesService } from './messages-data-service.service';
import { AuthService } from './auth.service';
import { NovedadService } from './novedades.service';


@Injectable({
    providedIn: 'root',
})
export abstract class ParametricoService<E extends Parametrico> extends BufferedDescriptivoService<E>{

    constructor(novedadesService: NovedadService, authService: AuthService, messServ?: MessagesService) {
        super(novedadesService, authService, messServ);
        this.loadData();
        this.data.subscribe(r => {
            this.habilitados.next(r.filter(v => v['habilitado']));
        })
    }

    public habilitados: BehaviorSubject<E[]> = new BehaviorSubject([]);

    public getHabilitados = (filtro?: Filtro, customLoading?: LoadingService): Promise<E[]> =>{

        return this.getAll(filtro,customLoading).then(r => r.filter(p => p['habilitado']))
    }
    private _default: E;

    public async getDefault(): Promise<E> {
        if (this.updating) {
            return this.updating.then(_d => {
                return Promise.resolve(this.default);
            })
        } else {
            return Promise.resolve(this.default);
        }

    }
    public get default() {
        if (!this._default) this._default = this._data ? this._data.filter(d => d.esDefault)[0] : null;
        return this._default;
    }

    public habilitar(id: number, customLoading?: LoadingService) {
        if (customLoading) { customLoading.addLoadingCount(); } else this.loadingService.addLoadingCount()
        return this.http.post(this.getBaseURL() + "habilitar", id).toPromise().then(r => {
            if (customLoading) customLoading.susLoadingCount(); else this.loadingService.susLoadingCount();
            this.novedadesService.check();
            return this.parseSingle(r)
        }).catch((e) => this.handleError(e, customLoading));
    }
    protected override loadData(_customLoading?: LoadingService): Promise<E[]> {
       return super.loadData().then((d:E[]) =>{
        this._default = d.find(data => data.esDefault) || d[0];
        return d;
       })

    }


}