import { environment } from './../../../environments/environment';
import { Novedad } from './../model/Novedad';


import { Injectable } from '@angular/core'
import { BehaviorSubject, firstValueFrom, lastValueFrom } from 'rxjs'
import { BaseService } from './base.service'
import { AuthService } from './auth.service';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class NovedadService extends BaseService {

    
    marcarVista(novedad: Novedad): Promise<Novedad> {
        return lastValueFrom(this.http
            .post(this.getBaseURL('marcarVista'), novedad)
            )
            .then((n) => {
                return Novedad.fromData(n)
            })
            .catch((e) => {
                console.debug(e)
                return Promise.reject(e)
            })
    }
    unregister(tipo: string, suscriber: IActualizable, idEntidad?: number, idLocal?: number) {
        const obs = this.observers.filter((o) => {
            return o.tipo == tipo && o.idEntidad == idEntidad
        })[0]
        if (obs) {
            obs.observadores = obs.observadores.filter((i) => i.getKey() != suscriber.getKey())
        }
    }
    public baseName(): string {
        return 'novedades'
    }
    public initiated: BehaviorSubject<boolean> = new BehaviorSubject(false)
    private _pushToken: string=""
    private unsuscribe() {
        return Promise.resolve(true)/*this.http
            .post(
                this.getApiURL() + 'novedades/desuscribir/all',
                this.observers.map((ob) => {
                    return {
                        tipo: ob.tipo,
                        idEntidad: ob.idEntidad,
                        destinatario: this._pushToken
                    }
                })
            )
            )
            .catch((e) => {})*/
    }
    private _currentLocal: number=0
    public get currentLocal(): number {
        return this._currentLocal
    }
    public set currentLocal(v: number) {
        if ((!this._currentLocal || this.currentLocal != v) && this.pushToken) {
            this.observers
                .filter((o) => !o.idEntidad)
                .forEach(async (o) => {
                    if (this.currentLocal) {
                        this.unsuscribe()
                    }
                    if (v) {
                       lastValueFrom(this.http
                            .post(this.getBaseURL('suscribir'), { tipo: o.tipo, destinatario: this.pushToken, idLocal: v })
                            )
                            .catch((e) => {})
                    }
                })
        }
        this._currentLocal = v
    }

    public get pushToken(): string {
        return this._pushToken || localStorage.getItem(environment.tokenKey + "_push") || ""
    }
    public set pushToken(v: string) {
        this._pushToken = v
        localStorage.setItem(environment.tokenKey + '_push', v)
    }
    private observers: ObseKey[] = []
    private static updateNovedades: BehaviorSubject<Novedad[]> = new BehaviorSubject([])

    constructor(private authService: AuthService) {
        super()
        NovedadService.updateNovedades.subscribe(this.notify)
        this.authService.onLogin.subscribe(l=>{
            this._pushToken = localStorage.getItem(environment.tokenKey + "_push")
        })
        this.authService.onLogout.subscribe((l) => {
            this.unsuscribe();
           // localStorage.removeItem(environment.tokenKey + "_push");
        })
    }
    public updateToken(token: string) {
        const prevToken = this._pushToken || localStorage.getItem(environment.tokenKey + '_push')
        if (prevToken != token) {
            this.pushToken = token
            if (prevToken) {
               lastValueFrom(this.http.post(this.getBaseURL('updateToken'), { anterior: prevToken, nuevo: token }))
            } else {
                this.observers.forEach((o) => {
                    o.observadores.forEach((obs) => {
                        this.registrarObservador(o.tipo, obs, o.idEntidad)
                    })
                })
            }
        }
    }
    public nuevoPush(msg: any) {
        if (msg.data) {
            NovedadService.updateNovedades.next([Novedad.fromData(msg.data)])
        }
    }
    private async _register(tipo: string, suscriber: IActualizable, idEntidad?: number, idLocal?: number, pushObserver: boolean = false) {
        let subs = this.observers.filter((o) => o.tipo == tipo && idEntidad == o.idEntidad)[0]
        if (!subs || subs.observadores.indexOf(suscriber) < 0) {
            if (!subs) {
                subs = new ObseKey({ tipo: tipo, idEntidad: idEntidad, idLocal: idLocal })
                this.observers.push(subs)
            }
            subs.observadores.push(suscriber)
            if (pushObserver) {
                    if (this.pushToken) {
                       lastValueFrom(this.http
                            .post(this.getBaseURL('suscribir'), { tipo: tipo, idEntidad: idEntidad, destinatario: this.pushToken, idLocal: this.currentLocal })
                            )
                            .catch((e) => {})
                    }
                
            }
        }
    }
    public registrarObservador(tipo: string, suscriber: IActualizable, idEntidad?: number, idLocal?: number) {
        this._register(tipo, suscriber, idEntidad, idLocal)
    }
    public registrarPushObserver(tipo: string, suscriber: IActualizable, idEntidad?: number, idLocal?: number) {
        this._register(tipo, suscriber, idEntidad, idLocal, true)
    }
    private checking: Promise<any>
    private lastUpdate: string
    public async check() :Promise<Novedad[]>{
        if (!this.lastUpdate) {
            this.lastUpdate = moment().format('YYYYMMDDHHmmss')
        }
        if (!this.checking && moment().diff(moment(this.lastUpdate,'YYYYMMDDHHmmss'), 'seconds') > 10 ) {
            this.checking = lastValueFrom(this.http
                .get(this.getBaseURL('all') + (this.lastUpdate ? '?lastCheck=' +this.lastUpdate : '')))
                .then((n:any) => {
                    this.lastUpdate = moment().format('YYYYMMDDHHmmss')
                    this.notify(n)
                    this.checking = null
                    return n;
                })
                .catch((e) => {
                    this.checking = null
                    return Promise.reject(e)
                })
                
        }else{
            return Promise.resolve([]);
        }
        return this.checking;
    }
    private notify = (r: Novedad[]) => {
        r.forEach((n) => {
            var o = this.observers.filter(
                (o) => o.tipo == n.tipo && (o.idEntidad == n.idEntidad || !o.idEntidad)
            )
            if (o) {
                o.forEach((oo) => oo.observadores.forEach((a) => a.next(n)))
            }
        })
    }
}
export class ObseKey {
    tipo: string
    idEntidad?: number
    observadores: IActualizable[] = []
    constructor(data: any) {
        this.tipo = data.tipo
        this.idEntidad = data.idEntidad
    }
}
export interface IActualizable {
    next(n: Novedad):void
    destroy():void
    getKey():string
}
