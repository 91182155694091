import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { DownloadFileComponent } from './pages/download-file/download-file.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
//##NUEVOIMPORT
const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled'
};

const routes: Routes = [
    { path: 'login', loadChildren: () => import('./pages/login2/login2.module').then(m => m.Login2Module) },
    { path: 'public/register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
    { path: 'public/verification', loadChildren: () => import('./pages/verification/verification.module').then(m => m.VerificationModule) },
    //{ path: 'public/registrarse', component: RegistrarseComponent, data: { animation: 'Item' }, }, // FALTA LA PAGINA DE REGISTRO
    { path: 'public/envio-recupero', loadChildren: () => import('./pages/forgotpassword/forgotpassword.module').then(m => m.ForgotPasswordModule) },
    { path: 'public/recupero-contrasenia/:token', loadChildren: () => import('./pages/newpassword/newpassword.module').then(m => m.NewPasswordModule) },
    { path: 'public/:tipo/:token', component: DownloadFileComponent },
    { path: '', redirectTo: 'landing', pathMatch: 'full' },     
    
    {
        path: '',
        component: AppLayoutComponent,
        children: [
            { path: '', redirectTo: 'landing', pathMatch: 'full' },           
            { path: 'usuario', data: { breadcrumb: 'Usuario'}, loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule) }, 
            {path: 'notificacion', loadChildren:()=>import('./pages/notificacion/notificacion.module').then(m=>m.NotificacionModule)},            
            { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
            {path: 'inversor', loadChildren:()=>import('./pages/inversor/inversor.module').then(m=>m.InversorModule)},
            {path: 'emprendedor', loadChildren:()=>import('./pages/emprendedor/emprendedor.module').then(m=>m.EmprendedorModule)},
            {path: 'emprendimiento', loadChildren:()=>import('./pages/emprendimiento/emprendimiento.module').then(m=>m.EmprendimientoModule)},
            {path: 'estado-evaluacion', loadChildren:()=>import('./pages/estado-evaluacion/estado-evaluacion.module').then(m=>m.EstadoEvaluacionModule)},
            { path: 'tipo-documentacion-adjunta', loadChildren:()=>import('./pages/tipo-documentacion-adjunta/tipo-documentacion-adjunta.module').then(m=>m.TipoDocumentacionAdjuntaModule)},
            { path: 'tipo-kpi', loadChildren:()=>import('./pages/tipo-kpi/tipo-kpi.module').then(m=>m.TipoKPIModule)},
            { path: 'categoria-inversor', loadChildren:()=>import('./pages/categoria-inversor/categoria-inversor.module').then(m=>m.CategoriaInversorModule)},
//##NUEVARUTA
           
        ]},
    { path: 'landing', loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule) },
    { path: '**', component: PageNotFoundComponent },

];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
