<span class="ql-formats">
    <select class="ql-header" *ngIf="!minimal">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option selected>Normal</option>
    </select>
    <select class="ql-font" *ngIf="!minimal">
        <option selected>Sans Serif</option>
        <option value="serif">Serif</option>
        <option value="monospace">Monospace</option>
    </select>
</span>
<span class="ql-formats">
    <button class="ql-bold" aria-label="Bold"></button>
    <button class="ql-italic" aria-label="Italic"></button>
    <button class="ql-underline" aria-label="Underline"></button>
</span>
<span class="ql-formats">
    <select class="ql-color"></select>
    <select class="ql-background"></select>
</span>
<span class="ql-formats" *ngIf="!minimal">
    <button class="ql-list" value="ordered" aria-label="Ordered List"></button>
    <select class="ql-align">
        <option selected></option>
        <option value="center"></option>
        <option value="right"></option>
        <option value="justify"></option>
    </select>
</span>
<span class="ql-formats" *ngIf="!minimal">
    <button class="ql-link" aria-label="Insert Link"></button>

    <button class="ql-clean" aria-label="Remove Styles"></button>
</span>
