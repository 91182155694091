export class Auditable {
    constructor(public lastModifiedBy?: string, public lastModifiedDate?: Date | string) {}

    public static fromData(data: any): Auditable {
        if (!data) return null
        const o: Auditable = new Auditable(data.lastModifiedBy, data.lastModifiedDate)

        return o
    }
}
