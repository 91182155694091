import { Directive, ElementRef, Input } from '@angular/core';
import { AuthService } from '../service/auth.service';

@Directive({
  selector: '[allowed]'
})
export class AllowedDirective {

  private _rol: string;
  private allowed: boolean = false;
  public get rol(): string {
    return this._rol;
  }
  @Input("allowedRol")
  public set rol(v: string) {
    this._rol = v;
    this.checkAllowed();
  }

  constructor(private el: ElementRef, private authService: AuthService) {

  }

  private checkAllowed() {
    let u = this.authService.getCurrentUser;
    if (u) {
      if (this.rol && u.tieneRol(["ROLE_ADMIN", this.rol])) {
        this.el.nativeElement.style.display = "visible";
      } else {
        this.el.nativeElement.style.display = "none";
      }
    } else {
      this.el.nativeElement.style.display = "none";
    }

  }

}
