<div class="profile-badge {{ class }}" [class.border-dashed]="dragging && !readonly"  >
    <div class="selected-overlay" *ngIf="selected" [@appear]>
        <i class="pi pi-check selected"></i>
    </div>

    <div class="badge  {{ class }} " [ngClass]="{ clickable: seleccionable && !readonly }" [class.readonly]="readonly"
        [ngStyle]="{ 'background-image': ' url(' + (profilePic ? profilePic : defaultImage) + ')' }"
        (click)="seleccionable && !readonly ? inputFile.click() : ''"></div>



</div>
<input pInputText type="file" (change)="handleFileInput($event)" #inputFile name="inputfile" [hidden]="true" accept="image/*" />


<p-dialog (onShow)="displayCropper()" header="" [(visible)]="crop" appendTo="body" [modal]="true" #cropper [styleClass]="'no-header gestor-dialog'">

    <img #image [src]="imageSource" crossorigin >

    <p-footer>
        <button pButton (click)="cancelar()" icon="pi pi-times"
            label="{{ 'CANCELAR' | translate }}"></button>
        <button pButton (click)="confirmar()" icon="pi pi-save" label="{{ 'CONFIRMAR' | translate }}"></button>
    </p-footer>

</p-dialog>