<div class="grid">
    <div class="col-fix seleccionar-todos" *ngIf="!readonly">
        <p-checkbox [(ngModel)]="allSelected" [binary]="true" (onChange)="updateSelection($event)"
            [ngModelOptions]="{'standalone':true}" [disabled]="!options?.length || readonly"
            pTooltip="{{'SELECCIONAR_TODOS' | translate}}"> </p-checkbox>
    </div>
    <div class="col">
        <span class="p-fluid p-float-label">
      
            <div class="grid">
                <div class="{{checkboxClass}}" *ngFor="let r of options; let i = index ">
                    <p-checkbox [value]="r" [label]="r.label" [(ngModel)]="selected" name="{{'op1' + i}}"
                        [disabled]="readonly">
                    </p-checkbox>
                </div>
            </div>
            <label *ngIf="label && !placeHolder" for="inputgroup">{{ header | translate }} <span class="danger" *ngIf="required && !readonly">*</span></label>
        </span>
    </div>


</div>
