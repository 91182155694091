import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'contactos',
  templateUrl: './contactos.component.html',
  styleUrls: ['./contactos.component.less']
})
export class ContactosComponent implements OnInit {


  private _data: any;
  public get data(): any {
    return this._data;
  }
  @Input()
  public set data(v: any) {
    this._data = v;
  }


  @Input()
  public whatsapp = true;


  @Input()
  public containerClass = "col-12";

  constructor() { }

  public sendEmail(event) {
    event.stopPropagation();
    if (this.data['email']) {
      window.open("mailto:" + this.data['email'], "_blank");
    }

  }

  public call(event) {
    event.stopPropagation();
    if (this.data['telefono']) {
      if (this.whatsapp) {
        window.open("whatsapp://send?phone=" + this.data['telefono'], "_blank");
      }
      else {
      window.open("tel:" + this.data['telefono'], "_blank");
      }
    }

  }
  ngOnInit() {
  }

}
