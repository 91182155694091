
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { AllowedDirective } from './common/directives/allowed.directive'
import { AutofocusDirective } from './common/directives/autofocus.directive'
import { DelayRenderDirective } from './common/directives/delay-render.directive'
import { Safe } from './common/directives/safe.directive'
import { TranslatePipe } from './common/pipes/translate.pipe'
import { PrimeNgModule } from './prime-ng.module'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslatePipeModule } from './common/pipes/translate-pipe.module'

const pipe = [Safe]
const directives = [AutofocusDirective, DelayRenderDirective, AllowedDirective]
@NgModule({
    imports: [PrimeNgModule, FormsModule,FontAwesomeModule,TranslatePipeModule ],
    providers: [],
    declarations: [...pipe, ...directives],
    exports: [...pipe, ...directives, FormsModule, PrimeNgModule,TranslatePipeModule],
})
export class CoreModule {}
