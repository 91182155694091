<span *ngFor="let m of initMessages">{{ m.toString() }}</span>
<span
    *ngIf="messages.length - init > 0"
    (click)="showHiddenMessages = !showHiddenMessages"
    ><label *ngIf="!showHiddenMessages"
        >y {{ messages.length - init }} más </label
    ><i
        [ngClass]="
            showHiddenMessages ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
        "
    ></i
></span>

<div *ngIf="showHiddenMessages">
    <span *ngFor="let m of hiddenMessages">{{ m.toString() }}</span>
</div>
