
import { Component, EventEmitter, forwardRef, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { IMoneda } from './IMoneda';
import { TranslateService } from '../../service/translate.service';
import { MonedaService } from '../../service/moneda.service';
import { Moneda } from '../../model/Moneda';
import { ErrorHandler } from '../../utils/ErrorsHandler';



@Component({
  selector: 'span-dinero-editable',
  templateUrl: './span-dinero-editable.component.html',
  styleUrls: ['./span-dinero-editable.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SpanDineroEditableComponent),
      multi: true
    },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => SpanDineroEditableComponent), multi: true }
  ]


})
export class SpanDineroEditableComponent implements OnInit, ControlValueAccessor, OnDestroy, Validator {


  @Input()
  public label: string;

  public importeForm: FormControl;
  @Input()
  public readonly: boolean = false;

  @Input()
  public tooltip: string;
  @Input()
  public editable: boolean = true;

  public editando: boolean = false;

  @Input()
  public moneda: Moneda;

  private _maxValue: number;
  public get maxValue(): number {
    return this._maxValue;
  }
  @Input()
  public set maxValue(v: number) {
    this._maxValue = v;


  }

  private _minValue: number;
  public get minValue(): number {
    return this._minValue;
  }
  @Input()
  public set minValue(v: number) {
    this._minValue = v;

  }

  @Inject(LOCALE_ID) public locale: string

  @Input()
  public decimals: number = 2;

  @Output()
  public onChange: EventEmitter<number> = new EventEmitter();
  public required: boolean = false;

  public disabled: boolean = false;
  @Input()
  public hideLabel: boolean = false;

  private _importeMuestra: number;
  public get importeMuestra(): number {
    return this._importeMuestra;
  }
  @Input()
  public set importeMuestra(v: number) {
    this._importeMuestra = v;
    if (this.importeForm)
      this.importeForm.setValue(v);
  }
  constructor(private monedaService:MonedaService, private translationService:TranslateService) {
      if(!this.moneda) {
        this.monedaService.getDefault().then(m=> this.moneda = m);
      }
  }

  public getDecimals() {
    return this.decimals > 2 ? this.decimals : 2;
  }



  private onChangeCallback: (_: any) => void = () => { };
  private onTouchedCallback: (_: any) => void = () => { };



  writeValue(obj: any): void {
    this.importeForm.setValue(obj);

  }
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  public editarPrecio(event) {
    if (this.editable && !this.disabled && !this.readonly)
      this.editando = true;

  }
  private minValidator = (control: AbstractControl) => {
    return (control && control.value && this.minValue && control.value < this.minValue) ? { 'min': { 'min': this.minValue } } : null;
  };
  private maxValidator = (control: AbstractControl) => {
    return (control && control.value && this.maxValue && control.value > this.maxValue) ? { 'max': { 'max': this.maxValue } } : null;
  };
  ngOnDestroy(): void {

  }

  ngOnInit() {
    this.importeForm = new FormControl(null, [this.minValidator, this.maxValidator]);
    if (this.importeMuestra) {
      this.importeForm.setValue(this.importeMuestra);
    }
    this.importeForm.valueChanges.pipe(debounceTime(200)).subscribe(r => {
      this.onChange.emit(r);
      this.onChangeCallback(r);
    })
  }
  validate(control: AbstractControl): ValidationErrors {

    control.setErrors(this.importeForm.errors);
    return control ? control.errors : null;
  }
  getErrorMessage(control: AbstractControl) {
    if (control?.errors) {
      return ErrorHandler.getErrorMessage(control);
    }
    return null;

  }
  public onInput(event){
    this.importeForm.setValue(event.value);
  }
  public onFocus(event: any) {
   /* event.srcElement.select();
    event.stopPropagation();*/
  }
}
