import { FilaProcesoCargaMasiva } from "./FilaProcesoCargaMasiva"

export class ProcesoCargaMasiva {


    constructor(public id?: number,
                public inicioProceso?: Date,
                public fechaFinProceso?: Date,
                public progreso?: number,
                public tipo?: string,
                public filasProcesadasError: FilaProcesoCargaMasiva[] = [],
                public header: string = '') {

                }

    public static fromData(data: any): ProcesoCargaMasiva {
        if (!data) return null
        let p = new ProcesoCargaMasiva()
        p.id = data.id
        p.tipo = data.tipo
        p.inicioProceso = data.inicioProceso? new Date(data.inicioProceso) : null
        p.fechaFinProceso = data.fechaFinProceso? new Date(data.fechaFinProceso) : null
        p.progreso = data.progreso
        p.filasProcesadasError = data.filasProcesadasError?.length ? data.filasProcesadasError.map((f:any) => FilaProcesoCargaMasiva.fromData(f)) : [] 
        p.header = data.header
        return p
    }

}