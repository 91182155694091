import { TranslateService } from './translate.service';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Message } from 'primeng/api';
import { LoadingService } from './loading-data-service.service';

@Injectable({
    providedIn: 'root',
})
export class MessagesService {
    public loadingService: LoadingService;
    public _life: number = 4000;
    constructor(public mService: MessageService, private translator: TranslateService) {
        this.loadingService = LoadingService.injector.get(LoadingService);
    }
    get life(): number {
        return this._life;
    }
    set life(val: number) {
        this._life = val;
    }
    public errorHandler = (error:any, msgs?: Message[]) => {

        let _error = { message: "" };
        if (!error.error || !error.error.message) {
            _error.message = "Error Interno";

        } else {

            _error.message = this.translator.get(error.error.message);

            //_error = error;
        }
        this.loadingService.resetLoadingCount();
        msgs = [];
        msgs.push({
            severity: 'error',
            life: this.life,
            detail: this.translator.get(_error.message)
        });
        this.cleanMessages();
        this.mService.add({
            severity: 'error',
            life: this.life,
            detail: this.translator.get(_error.message)
        });
        return Promise.reject(_error.message);

    }
    public cleanMessages() {
        this.mService.clear();
    }
    public success(message?: string, titulo?: string, customLife?: number) {
        this.mService.add({
            severity: 'success',
            life: customLife ? customLife : this.life,
            detail: this.translator.get(message || ""), summary: this.translator.get(titulo|| "Success")
        });
    }
    public error(message: string, titulo?: string, customLife?: number) {
        this.mService.add({
            severity: 'error', life: customLife ? customLife : 10000,
            detail: this.translator.get(message), summary: this.translator.get(titulo || "Error")
        });
    }
    public info(message: string, titulo?: string, customLife?: number) {
        this.mService.add({
            severity: 'info', life: customLife ? customLife : this.life,
            detail: this.translator.get(message), summary: this.translator.get(titulo||"Info")
        });
    }
    public warning(message: string, titulo?: string, customLife?: number) {
        this.mService.add({
            severity: 'warn', life: customLife ? customLife : 8000,
            detail: this.translator.get(message), summary: this.translator.get(titulo||"Warning")
        });
    }

}