import { TranslatePipeModule } from './common/pipes/translate-pipe.module';

import { ColorPickerComponent } from './common/components/color-picker/color-picker.component';
import { LoadingService } from './common/service/loading-data-service.service';
import { ClipboardModule } from 'ngx-clipboard';
import { NgModule } from '@angular/core';
import { LayoutPickerComponent } from './common/components/layout-picker/layout-picker.component';
import { ContactosComponent } from "./common/components/contactos/contactos.component"
import { DefaultToolbarComponent } from "./common/components/default-toolbar/default-toolbar.component"
import { FiltroCompoenent } from "./common/components/filtro/filtro.component"
import { SelectorCheckboxComponent } from "./common/components/selector-checkbox/selector-checkbox.component"
import { SelectorDiaComponent } from "./common/components/selector-dia/selector-dia.component"
import { SelectorMultipleChipsComponent } from "./common/components/selector-multiple-chips/selector-multiple-chips.component"
import { SpanDineroEditableComponent } from "./common/components/span-dinero-editable/span-dinero-editable.component"
import { SpanListComponent } from "./common/components/span-list/span-list.component"
import { AccionesVisiblesPipe } from "./common/pipes/accionesVisibles.pipe"
import { FillPipe } from "./common/pipes/fill.pipe"
import { ModelGestorComponent } from './common/components/model-gestor/model-gestor.component';
import { ModelListComponent } from './common/components/model-list/model-list.component';
import { ParametricoSelectorComponent } from './common/components/parametrico-selector/parametrico-selector.component';
import { ProfileBadgeComponent } from './common/components/profile-badge/profile-badge.component';
import { LoadingPanelComponent } from './common/components/loading-panel/loading-panel.component';
import { SelectorIdiomaComponent } from './common/components/selector-idioma/selector-idioma.component';
import { GestorParametricoComponent } from './common/components/gestor-parametrico/gestor-parametrico.component';
import { CoreModule } from './core.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DescriptivoModule } from './common/components/descriptivo-material-selector/descriptivo.module';

import { PrimeNgModule } from './prime-ng.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { DragDropFileUploadDirective } from './common/directives/dnd.directive';


const pipe = [FillPipe,  AccionesVisiblesPipe]
const directives = []
const imports = [
    CoreModule, 
    ReactiveFormsModule,
    DescriptivoModule, 
    ColorPickerModule,
    ClipboardModule,
    TranslatePipeModule]
const components = [
    ContactosComponent,
    SelectorCheckboxComponent,
    SelectorDiaComponent,    
    ColorPickerComponent,
    SelectorMultipleChipsComponent,
    SpanDineroEditableComponent,
    SpanListComponent,
    DefaultToolbarComponent,
    FiltroCompoenent,
    LayoutPickerComponent,
    ModelGestorComponent,
    ModelListComponent,
    ParametricoSelectorComponent,
    ProfileBadgeComponent,
    LoadingPanelComponent,
    SelectorIdiomaComponent,
    GestorParametricoComponent,
    DragDropFileUploadDirective
]
@NgModule({
    imports:[...imports],
    providers: [LoadingService],
    declarations: [...directives, ...pipe, ...components],
    exports: [...directives, ...pipe, ...components, CoreModule, DescriptivoModule, PrimeNgModule, TranslatePipeModule],
})
export class CommonComponentsModule {}
