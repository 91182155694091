import { Injectable } from '@angular/core'
import { environment } from './../../../environments/environment'
import { Descriptivo } from './../model/Descriptivo'
import { ConfiguracionUsuarioService } from './configuracion-usuario.service'

@Injectable({
    providedIn: 'root',
})
export class DefaultService {
    private static KEY = 'defaults' + environment.tokenKey
    private _map: Map<string, Descriptivo> = new Map()
    constructor(private confService: ConfiguracionUsuarioService) {}
    async cleanDefault(name: string) {
        let conf = await this.confService.get()
        delete conf.defaults[name]
        this.confService.save()
    }

    public clean() {
        this.confService.get().then((d) => {
            d.defaults = new Map()
            this.confService.save()
        })
    }

    public async getDefault(name) {
        return (await this.confService.get())?.defaults[name] || null;
    }
    public async setDefault(name: string, data: any) {
        let conf = await this.confService.get()
        conf.defaults[name] = Descriptivo.fromData(data)
        this.confService.save()
    }
}
