<div class="layout-topbar">
    <div class="layout-topbar-start">
        <a class="layout-topbar-logo" routerLink="/home">
           <img src="assets/layout/vector-logo.svg" alt="" style="width: 100%; padding: 0 0.75rem;">
        </a>
        
    </div>

    <div class="layout-topbar-end">
        <div class="layout-topbar-actions-start">
            
        </div>
        <div class="layout-topbar-actions-end">
            <ul class="layout-topbar-items">

                <li>
                    <a pStyleClass="@next" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout" [hideOnOutsideClick]="true" pRipple>
                        <i class="pi pi-bell" pBadge severity="warning"></i>
                    </a>
                    <div class="ng-hidden">
                        <!--ul class="list-none p-0 m-0">
                            <li class="px-3 py-1">
                                <span>You have <b>4</b> new notifications</span>
                            </li>
                            <li class="p-3">
                                <div class="flex align-items-center">
                                    <img src="assets/demo/images/avatar/avatar-1.png" />
                                    <div class="flex flex-column ml-3 flex-1">
                                        <div class="flex align-items-center justify-content-between mb-1">
                                            <span class="font-bold">Jerome Bell</span>
                                            <small>42 mins ago</small>
                                        </div>
                                        <span class="text-sm line-height-3">How to write content about your photographs?</span>
                                    </div>
                                </div>
                            </li>
                            <li class="p-3">
                                <div class="flex align-items-center">
                                    <img src="assets/demo/images/avatar/avatar-2.png"/>
                                    <div class="flex flex-column ml-3 flex-1">
                                        <div class="flex align-items-center justify-content-between mb-1">
                                            <span class="fs-small font-bold">Cameron Williamson</span>
                                            <small>48 mins ago</small>
                                        </div>
                                        <span class="text-sm line-height-3">Start a blog to reach your creative peak.</span>
                                    </div>
                                </div>
                            </li>
                            <li class="p-3">
                                <div class="flex align-items-center">
                                    <img src="assets/demo/images/avatar/avatar-3.png"/>
                                    <div class="flex flex-column ml-3 flex-1">
                                        <div class="flex align-items-center justify-content-between mb-1">
                                            <span class="fs-small font-bold">Anna Miles</span>
                                            <small>1 day ago</small>
                                        </div>
                                        <span class="text-sm line-height-3">Caring is the new marketing</span>
                                    </div>
                                </div>
                            </li>
                            <li class="p-3">
                                <div class="flex align-items-center">
                                    <img src="assets/demo/images/avatar/avatar-4.png"/>
                                    <div class="flex flex-column ml-3 flex-1">
                                        <div class="flex align-items-center justify-content-between mb-1">
                                            <span class="fs-small font-bold">Arlene Mccoy</span>
                                            <small>4 day ago</small>
                                        </div>
                                        <span class="text-sm line-height-3">Starting your traveling blog with Vasco.</span>
                                    </div>
                                </div>
                            </li>
                        </ul-->
                    </div>
                </li>
               <li>
                <a pStyleClass="@next" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout" 
                (click)="logoff()" [hideOnOutsideClick]="true" pRipple>
                    <i class="pi pi-power-off" ></i>
                </a>
               </li>
            </ul>
        </div>
    </div>
</div>
