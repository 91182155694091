
import { Injectable } from '@angular/core'
import { HttpRequest, HttpResponse } from '@angular/common/http'
import { lastValueFrom } from 'rxjs'
import { ServicioAbstract } from './service.service'
import { ProcesoCargaMasiva } from '../model/ProcesoCargaMasiva'
import { LoadingService } from './loading-data-service.service'

@Injectable({
    providedIn: 'root',
})
export class CargaMasivaService extends ServicioAbstract<ProcesoCargaMasiva> {
    public newEnt(): ProcesoCargaMasiva {
        return new ProcesoCargaMasiva()
    }

    baseName() {
        return 'carga-masiva'
    }

    constructor() {
        super()
    }

    public parseToEnt(data: any): ProcesoCargaMasiva {
        return ProcesoCargaMasiva.fromData(data)
    }

    cargaMasiva(tipo: string, file: File, customLoading?: LoadingService) {
        let $this = this
        if (customLoading) {
            customLoading.addLoadingCount()
        } else this.loadingService.addLoadingCount()

        const frmData = new FormData()
        if (file) frmData.append('file', file)
        frmData.append('tipo', encodeURIComponent(tipo))
        const request = new HttpRequest('POST', this.getBaseURL(), frmData)
        return lastValueFrom(this.http.request(request))
            .then((r: any) => {
                if (customLoading) {
                    customLoading.susLoadingCount()
                } else this.loadingService.susLoadingCount()
                return $this.parseToEnt(r?.body)
            })
            .catch((e) => this.handleError(e, customLoading))
    }
}
