import { AbstractControl } from '@angular/forms';
export class ErrorHandler {

    public static getErrorMessage(formControl: AbstractControl) {
        if (!formControl) return null;
        if (formControl.hasError('required')) {
            return 'El campo es obligatorio';
        }

        if (formControl.hasError('email')) {
            return 'El email tiene que tener el formato "nombre@dominio.com"'
        }
        if (formControl.hasError('pattern')) {
            return 'El campo no cumple con el formato requerido'
        }
        if (formControl.hasError('cuitInvalido')) {
            return 'El cuit debe tener formato 99-99999999-9';
        }
        if (formControl.hasError('multiMailInvalido')) {
            return 'Los emails deben tener el formato "nombre@dominio.com" y separados por ;';
        }


        if (formControl.hasError('incorrect')) {
            return 'Debe elegir una opción del listado';
        }
        if (formControl.hasError('min')) {
            return 'No puede ingresar un valor menor a ' + formControl.errors['min']['min'];
        }
        if (formControl.hasError('max')) {
            return 'No puede ingresar un valor mayor a ' + formControl.errors['max']["max"];
        }
        return null
    }

}