
import { animate, style, transition, trigger } from '@angular/animations'
import { Location } from '@angular/common'
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { FormGroup, NgForm } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import * as moment from 'moment'
import { ConfirmationService } from 'primeng/api'
import { MenuItem } from 'primeng/api'
import { Subscription } from 'rxjs'
import { SessionComponent } from 'src/app/common/components/session-component.component'
import { Idioma } from 'src/app/common/model/Idioma'
import { Auditable } from '../../model/Auditable'
import { HelpService } from '../../service/help.service'
import { IdiomaService } from '../../service/idioma.service'
import { MessagesService } from '../../service/messages-data-service.service'
import { ServicioAbstract } from '../../service/service.service'
import { AppComponent } from './../../../app.component'

@Component({
    selector: 'model-gestor',
    templateUrl: 'model-gestor.component.html',
    styleUrls: ['model-gestor.component.less'],
    animations: [
        trigger('showUp', [
            transition(':enter', [style({ opacity: 0 }), animate('0.4s 0.1s ease-in-out', style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate('0.4s 0.1s ease-in-out', style({ opacity: 0 }))]),
        ]),
    ],
})
export class ModelGestorComponent extends SessionComponent implements OnInit, OnDestroy {
    mostrarAuditar = false
	auditableItem: Auditable = new Auditable()
    isSaving: boolean = false;
    @Input()
    public baseName
    constructor(
        public route: ActivatedRoute,
        public confirmationService: ConfirmationService,
        public activeRoute: ActivatedRoute,
        public appComponent: AppComponent,
        private idiomaService: IdiomaService,
        protected _location: Location,
        messagesService: MessagesService,
        protected router: Router,
        public helpService: HelpService    ) {
        super(messagesService)
    }

    private _menuItemAcciones: MenuItem[] = [
        {
            label: 'Volver',
			icon: 'fa fa-step-backward',
            command: () => {
                this.volver()
            },
        },
    ]

    public get menuItemAcciones(): MenuItem[] {
        return this._menuItemAcciones
    }
    @Input()
    public set menuItemAcciones(v: MenuItem[]) {
        this._menuItemAcciones = v
    }

    formChange: Subscription
    private original: string = ''
    @Input()
    public hideTitle: boolean = false

    @Input()
    public vista: boolean = false
    @Input()
    public modelName: string = ''
    @Input()
    public title: string = ''
    @Input()
    public isModal: boolean = false
    @Input()
    public subheader: string
    @Input()
    public hideButtons: boolean = false
    @Input()
    public conArchivo: boolean = false
    @Output()
    public onItemGuardado: EventEmitter<any> = new EventEmitter<any>()
    @Output()
    public readonlyChange: EventEmitter<boolean> = new EventEmitter<boolean>()

    @Output()
    public onEditar: EventEmitter<boolean> = new EventEmitter<boolean>()

    @Output()
    public onCancelar: EventEmitter<any> = new EventEmitter<any>()

    @Input()
    public validar: (r: any) => boolean = (r) => {
        return this.form ? this.form.valid : true
    }

    @Input()
    public txtOkMessage: string
    @Input()
    public multilenguaje: boolean = false

    @Input()
    public loadEntity: (id: number, idioma?: string) => void = this.defaultLoadEntity

    @Input()
    public verAcciones = false
    @Input()
    public txtPerdidaCambios: string

    private defaultLoadEntity(id: number, idioma: string) {
        if (this.service) {
            this.service.getById(id, null, idioma).then((r) => {
                this.item = r
            })
        }
    }
    private _idiomaSeleccionado: Idioma
    public get idiomaSeleccionado(): Idioma {
        return this._idiomaSeleccionado
    }
    @Input()
    public set idiomaSeleccionado(v: Idioma) {
        var prev = this._idiomaSeleccionado?.codigo
        this._idiomaSeleccionado = v

        if (v && this.item?.id && v?.codigo != prev) {
            this.loadEntity(this.item.id, v.codigo)
        }
    }

    private _form: FormGroup | NgForm
    public get form(): FormGroup | NgForm {
        return this._form
    }
    @Input()
    public set form(v: FormGroup | NgForm) {
        this._form = v
        if (this._form) {
            /*	
			
			this.modificado = false;
				this.formChange = this._form.valueChanges.pipe(distinctUntilChanged()).subscribe(r => {
					setTimeout(() => {
						this.modificado = JSON.stringify(this.item) != this.original;
						if (this.modificado) this.formChange.unsubscribe();
					});
	
				});
				*/
        }
    }

    public editar() {
        if (this.editable && this.item?.id) {
            this.readonly = false
            this.readonlyChange.emit(false);
            this.onEditar.emit(true);
            const url = this.baseName || this.service?.baseName()
            this.router.navigate([url + '/edit'], {queryParams: {
                id: this.item.id,
            }},)
        }
    }
    @Input()
    public override editable = false
    @Input()
    public readonly: boolean = false

    private defaultTxtOkMessage: string

    private defaultTxtPerdidaCambios: string

    @Input()
    public goBack: boolean = true

    @Input()
    public service: ServicioAbstract<any>
    private _item: any
    public get item(): any {
        return this._item
    }
    @Input()
    public set item(v: any) {
        if (v && ((v.id && this.item != v) || !v.id) && !this.original) {
            this.original = JSON.stringify(v)
            if (v['codigoIdioma'] != this.idiomaSeleccionado?.codigo) {
                this.idiomaService.getByCodigo(v['codigoIdioma']).then((r) => {
                    this.idiomaSeleccionado = r
                })
            }
        }
        this._item = v
        this.itemChange.emit(this._item)
        if (this.item && this.item['lastModifiedBy']) {
            if (!this.menuItemAcciones.find((m) => m.label == 'Auditar'))
                this.menuItemAcciones.push({
                    label: 'Auditar',
					icon: 'fa fa-search',
                    command: () => {
                        this.auditar()
                    },
                })
        }
    }

    @Output()
    public itemChange: EventEmitter<any> = new EventEmitter<any>()
    @Input()
    public persist: boolean = true
    @Input()
    public file: File
    public modificado: boolean = true

    @Input()
    public styleClass: string = 'white'

    ngOnInit() {
        this.subs.push(this.formChange)
        this.subs.push(
            this.activeRoute.data.subscribe((u) => {
                if (u?.['vista']) {
                    this.readonly = true
                }
            })
        )
    }
    auditar() {
        this.mostrarAuditar = true
		this.auditableItem = Auditable.fromData(this.item)
		this.auditableItem.lastModifiedDate = moment(this.auditableItem.lastModifiedDate).format('DD/MM/YYYY HH:mm:ss')
    }
    public formValid() {
        return this.form ? this.form.valid : true
    }
    public override volver() {
        if (this.goBack) this._location.back()
    }
    public async guardar(goBack:boolean = true) {
        this.isSaving = true
        if (this.formValid() && (await this.validar(this.item))) {
            if (this.idiomaSeleccionado) {
                this._item['codigoIdioma'] = this.idiomaSeleccionado.codigo
            }
            if (!this.persist) {
                this.itemChange.emit(this.item)
                this.onItemGuardado.emit(this.item)
                this.isSaving = false
                //this.modificado = false;
                goBack && this.volver()
            } else if (this.conArchivo) {
                this.isSaving = false
                this.service.guardarConArchivo(this.item, this.file).then((r) => {
                    this.success(this.okMessage)
                    this.itemChange.emit(r)
                    this.onItemGuardado.emit(r)
                    //this.modificado = false;
                    goBack && this.volver()
                    this.original = JSON.stringify(this.item)
                    this.checkModificado()
                    return r;
                }).catch(e=>{
                    
                    this.isSaving =false;
                    return Promise.reject(e);
                })
            } else {
                this.isSaving = false
               return  this.service.guardar(this.item).then((r) => {
                    this.success(this.okMessage)
                    this.itemChange.emit(r)
                    this.onItemGuardado.emit(r)
                    //this.modificado = false;
                    goBack && this.volver()
                    this.original = JSON.stringify(this.item)
                    this.checkModificado()
                    return r;
                }).catch(e=>{
                    this.isSaving = false;
                    return Promise.reject(e);
                })
            }
        } else {
            this.isSaving = false
            return Promise.reject(this.error('Verifique los campos del formulario'))
        }
    }
    checkModificado() {
        let $this = this
        /*this.formChange = this._form.valueChanges.subscribe(r => {
			setTimeout(() => {
				$this.modificado = JSON.stringify($this.item) != $this.original;
				if ($this.modificado) $this.formChange.unsubscribe();
			});
			
		*/
    }

    get finalTitle(): string {
        const desc = this.item['descripcion'] ? this.item['descripcion'] : '#' + this.item.id
        return (
            this.title ||
            (this.item?.id && this.readonly && `${this.translateService.get('VISUALIZANDO')} ${this.modelName} ${desc}`) ||
            (this.item?.id && `${this.translateService.get('EDITANDO')} ${this.modelName} ${desc}`) ||
            this.translateService.get('NUEVO') + ' ' + this.modelName
        )
    }

    get labelCancelar() {
        return this.modificado && !this.readonly ? this.translateService.get('CANCELAR') : this.translateService.get('VOLVER')
    }
    public cancelar() {
        if (this.modificado && !this.readonly) {
            this.confirmationService.confirm({
                key: 'genConfMG',
                header: this.translateService.get('GUARDAR_CAMBIOS'),
                message: this.perdidaCambios,
                accept: () => {
                    if (!this.isModal || (this.isModal && this.goBack)) this._location.back()
                    this.onCancelar.emit()
                },
            })
        } else {
            if (!this.isModal || (this.isModal && this.goBack)) this._location.back()
            this.onCancelar.emit()
        }
    }

    private get okMessage() {
        return this.txtOkMessage ? this.txtOkMessage : this.defaultTxtOkMessage
    }
    private get perdidaCambios() {
        return this.txtPerdidaCambios ? this.txtPerdidaCambios : this.defaultTxtPerdidaCambios
    }
    public override updateTexts() {
        super.updateTexts()
        this.defaultTxtPerdidaCambios = this.translateService.get('PERDIDA_CAMBIOS')
        this.defaultTxtOkMessage = this.translateService.get('OK_GUARDADO')
    }

    public goToHelp() {
        this.helpService.goToHelp(this.router.url.split('/')[1]) 
     }
     public hasHelp(){
         return !this.router.url.includes('help') && this.helpService.hasHelp(this.router.url.split('/')[1])
     }
}
