import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class TranslateService {
  data: any = {};
  dataCodebase: any = {};
  public idioma: string = 'es'
  private traducciones:any = {};
  constructor(private http: HttpClient) {

  }
  get(key: string) {
    let valor = this.data[key] ? this.data[key] : null;
    if (valor)
      return valor;
    return this.dataCodebase[key] ? this.dataCodebase[key] : key;
  }
  getOrKey(key: string) {
    let valor = this.data[key] || key;
    return valor;
  }
  upateData: BehaviorSubject<boolean> = new BehaviorSubject(false);

  use(lang: string): Promise<{}> {
    //this.idioma = lang;
    if(lang.toLowerCase().includes("es-")) lang = "es";
    if(lang.toLowerCase().includes("en-")) lang = "en";
    if(this.traducciones[lang]){
      if(this.traducciones[lang] instanceof Promise){
        return this.traducciones[lang];
      }else {
        this.data = Object.assign({}, this.traducciones[lang] || {});
        this.upateData.next(true);
        return Promise.resolve(this.traducciones[lang]);
      }
    }
    this.traducciones[lang] = new Promise<{}>((resolve, reject) => {
      const langPath = `assets/i18n/${lang? lang.toLowerCase() : 'es'}.json`;
      lastValueFrom(this.http.get<{}>(langPath)).then(
        translation => {
          this.data = Object.assign({}, translation || {});
          resolve(this.data);
          this.upateData.next(true);
          this.idioma = lang;
          this.traducciones[lang] = this.data;
        }).catch(
        error => {
          this.data = {};
          if (lang == "es") {
            resolve(this.data);
            console.log("NO EXISTE ARCHIVO DEFAULT DE TEXTOS");
          } else {
            this.use("es");
            console.log("NO EXISTE " + lang + " DEFAULT DE TEXTOS");
          }
        }
      );
    });
    return this.traducciones[lang];
  }

  cargarDataCodebase(lang: string): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
      const langPath = `assets/i18n/es.json`;
      lastValueFrom(this.http.get<{}>(langPath)).then(
        translation => {
          this.dataCodebase = Object.assign({}, translation || {});
          resolve(this.dataCodebase);
          this.upateData.next(true);
        }).catch(
        error => {
          this.dataCodebase = {};
          if (lang == "es") {
            resolve(this.dataCodebase);
            console.log("NO EXISTE ARCHIVO DEFAULT DE TEXTOS");
          } else {
            this.cargarDataCodebase("es");
            console.log("NO EXISTE " + lang + " DEFAULT DE TEXTOS");
          }
        }
      );
    });
  }
}



