import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

@Injectable({
    providedIn: 'root',
})
export class HelpService {
    public helpArray: string[] = ['proveedor', 'usuario', 'gestores', 'listados', 'factura', 'lista-precios', 'cliente', 'vehiculo', 'producto', 'tipo-notificacion', 'emailtemplate', 'carga-masiva', 'itinerario', 'actividades' , 'reserva']
    constructor(private router: Router) {}

    public goToHelp(destino: string) {
        this.router.navigate(['help', destino])
    }

    public hasHelp(url: string): boolean {
        return this.helpArray.includes(url)
    }
}
