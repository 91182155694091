import { Component, Input, OnInit } from '@angular/core';
import { commonAnimations } from 'src/app/common-animations';
import { LoadingService } from '../../service/loading-data-service.service';
import { GlobalInjector } from './../../GlobalInjector';
@Component({
	selector: 'loading-panel',
	templateUrl: './loading-panel.component.html',
	styleUrls: ['./loading-panel.component.less'],
	animations: [
		commonAnimations
	]
})

export class LoadingPanelComponent implements OnInit {

	@Input()
	private loadingService: LoadingService;
	@Input()
	public loading: boolean = false;
	constructor() {
		this.loadingService = GlobalInjector.InjectorInstance.get(LoadingService);

	}

	ngOnInit() {
		this.loadingService.loading.subscribe(l => {
			this.loading = l;
		});
	}
}