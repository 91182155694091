import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Message } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { environment } from 'src/environments/environment';
import { Usuario } from '../model/Usuario';
import { AuthService } from '../service/auth.service';
import { FilterService } from '../service/filter-service';
import { LoadingService } from '../service/loading-data-service.service';
import { MessagesService } from '../service/messages-data-service.service';
import { TranslateService } from '../service/translate.service';
import { GlobalInjector } from './../GlobalInjector';
import { ErrorHandler } from './../utils/ErrorsHandler';

@Injectable()
export abstract class SessionComponent implements OnDestroy {
    protected subs: Subscription[] = [];
    public yearRangeNacimiento: string;
    public environment = environment;
    public todos: Descriptivo = Descriptivo.TODOS();
    public finalizado = false;
    public editable = false;
    private loadingService: LoadingService;
    protected authService: AuthService;

    public permiteEdicion: boolean = true;

    public now: Date = moment().startOf('day').toDate();
    public prevView: string;
    locale: any[] = [];
    calendarioLocale: any;
    get tieneAyuda(): boolean {
        return false;
    }
    private authSub: Subscription;
    private routerNav: Router;
    public fitlerServices: FilterService;
    private translateSub: Subscription;
    public translateService: TranslateService;
    public customLoadingService: LoadingService = new LoadingService();
    constructor(
        public messagesService?: MessagesService,
        public currentView?: string
    ) {
        this.loadingService = LoadingService.injector.get(LoadingService);
        this.authService =
            GlobalInjector.InjectorInstance.get<AuthService>(AuthService);
        this.routerNav = GlobalInjector.InjectorInstance.get<Router>(Router);
        this.translateService =
            GlobalInjector.InjectorInstance.get<TranslateService>(
                TranslateService
            );
        this.translateService.upateData
            .pipe(filter((r) => r != null))
            .subscribe((r) => {
                this.calendarioLocale =
                    this.translateService.get('CALENDARIO_LOCALE');
            });
        this.fitlerServices =
            GlobalInjector.InjectorInstance.get<FilterService>(FilterService);

        this.translateSub = this.translateService.upateData.subscribe((t) => {
            this.updateTexts();
        });
        this.yearRangeNacimiento = this.getYearRange(65, 15);
        if (!this.messagesService)
            this.messagesService =
                GlobalInjector.InjectorInstance.get(MessagesService);
    }
    ngOnDestroy(): void {
        if (this.authSub) this.authSub.unsubscribe();
        if (this.translateSub) this.translateSub.unsubscribe();
        if (this.subs)
            this.subs.forEach((s) => {
                if (s) s.unsubscribe();
            });
    }
    public compareDescriptivos(v1: Descriptivo, v2: Descriptivo) {
        return !v1 || !v2
            ? false
            : v1.id && v2.id
            ? v1.id == v2.id
            : v1.codigo == v2.codigo;
    }

    public getUsersLocale(defaultValue: string): string {
        /* if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
            return defaultValue;
        }
        const wn = window.navigator as any;
        let lang = wn.languages ? wn.languages[0] : defaultValue;
        lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;*/
        return 'es-AR';
    }

    public getYearRange(from: number, to: number) {
        return (
            moment().startOf('year').subtract(from, 'years').year() +
            ':' +
            moment().startOf('year').subtract(to, 'years').year()
        );
    }
    public onFocus(event: any) {
        event.srcElement.select();
    }

    addLoadingCount() {
        this.loadingService.addLoadingCount();
    }

    susLoadingCount() {
        this.loadingService.susLoadingCount();
    }

    isUserLogged(): boolean {
        return this.authService.isLoggedIn;
    }
    public get unidadPeso() {
        return 'KG';
    }
    public tieneRol(rol: string) {
        return this.authService.tieneRol(rol);
    }
    get esAdministrador() {
        return this.authService.esAdministrador;
    }

    get esJefeAdministracion() {
        return (
            this.authService.esJefeAdministracion ||
            this.authService.esAdministrador
        );
    }

    get esSoloVisualizador() {
        return this.authService.esSoloVisualizador;
    }

    getCurrentUser(): Observable<Usuario> {
        return this.authService.getUser();
    }

    getUserFullName(): string {
        return this.authService.getUserFullName();
    }
    errorHandler = (error: any, msgs?: Message[]) => {
        this.messagesService.errorHandler(error, msgs);
    };
    protected clearCredentials(): Promise<any> {
        return this.authService.logout();
    }
    public get lenguaje() {
        return this.translateService.idioma;
    }
    public success(mensaje?: string, titulo?: string) {
        this.messagesService.success(mensaje, titulo);
        return true;
    }
    public error(mensaje?: string, titulo?: string) {
        this.messagesService.error(mensaje, titulo);
        return false;
    }
    public info(mensaje?: string, titulo?: string) {
        this.messagesService.info(mensaje, titulo);
    }
    public warning(mensaje?: string, titulo?: string) {
        this.messagesService.warning(mensaje, titulo);
    }

    set messageLife(val: number) {
        this.messagesService.life = val;
    }

    public goTo(vista: string) {
        this.prevView = this.currentView;
        this.currentView = vista;
    }

    public goToEnt(event: any, vista: string, id: number) {
        event?.stopPropagation && event.stopPropagation();
        this.routerNav.navigate([vista + '/vista'], { queryParams: { id } });
    }

    public volver() {}
    get usuario(): Usuario {
        return this.authService.getCurrentUser;
    }

    isTablet() {
        const width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    public updateTexts() {}
    public openLink = (link, nuevaVentana: boolean = true) => {
        window.open(link, nuevaVentana ? '_blank' : '');
    };
    public onDialogShow(event: any, dialog: any) {
        if (this.isMobile()) {
            dialog.maximized = true;
        }
    }
    scrollToElement($element): void {
        $element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        });
    }
    getErrorMessage(control: any) {
        return control?.errors ? ErrorHandler.getErrorMessage(control) : null;
    }

    get timezone() {
        return environment.timezone;
    }
}
