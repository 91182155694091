import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './../common/service/auth.service';
import { Subscription } from 'rxjs';
import { Usuario } from './../common/model/Usuario';
import { MenuItem } from 'primeng/api';
import { MenuConfigService } from './service/menu-config.service';
import { OnInit, Input, OnDestroy } from '@angular/core';
import { Component } from '@angular/core';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit , OnDestroy{
    public results: MenuItem[] = []
    private usuario: Usuario;
    private userSub: Subscription;
    public _menuItems: MenuItem[];
    
    @Input() reset: boolean;
    public searchStr: string
    exit = {
        label: 'Cerrar sesión',
        visible: true,
        command: () => {
            this.authService.logout()
            this.router.navigate(['login'])
        },
        icon: 'pi pi-sign-out ',
    }
    model: any[] = [];
    constructor(private menuConfService:MenuConfigService, private authService:AuthService, private router:Router){

    }
    public search(event) {
        this.results = this._menuItems.filter((m) => m.label.toUpperCase().trim().includes(event.query.toUpperCase().trim()))
    }
    public navegarParam(path, multilenguaje: boolean = false) {
        this.router.navigate(["/parametros"], { queryParams: { "tipo": path, "multi": multilenguaje } });
    }

    public navegar(path, query) {
        this.router.navigate([path], { queryParams: query });
    }
    ngOnDestroy(): void {
        if (this.userSub) this.userSub.unsubscribe;
    }
    ngOnInit() {
        this._menuItems = [];
        this.authService.getUser().pipe(filter(u => u != undefined)).subscribe(u => {
            this.usuario = u;
        })
        this.menuConfService.menuBS.subscribe((m) => {
            this._menuItems = []
            m.forEach((menu) => {
                if (menu.items?.length) {
                    this._menuItems = this._menuItems.concat(menu)
                } else {
                    this._menuItems.push(menu)
                }
            })
        })
    }
}
