
import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { Usuario } from 'src/app/common/model/Usuario';


export class Inversor  {
    constructor(
		public id ?: number, 
		public nombres ?: string, 
		public apellidos ?: string, 
		public tipoDocumento ?: Descriptivo, 
		public numeroDocumento ?: string, 
		public linkedin ?: string, 
		public usuario ?: Descriptivo, 
		public categoriaInversor?: Descriptivo,

    ) {
    }
    public static fromData(data: any): Inversor {
        if (!data) return null;
        const o: Inversor = new Inversor(
			data.id, 
			data.nombres, 
			data.apellidos, 
			Descriptivo.fromData(data.tipoDocumento), 
			data.numeroDocumento, 
			data.linkedin, 
			Usuario.fromData(data.usuario), 
			Descriptivo.fromData(data.categoriaInversor)

        );

        return o;

    }

}