import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef } from '@angular/core'
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms'
import { MenuItem } from 'primeng/api'
import { Observable } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { DefaultService } from '../../service/default.service'
import { DescriptivosService } from '../../service/descriptivos.service'
import { LoadingService } from '../../service/loading-data-service.service'
import { Descriptivo } from './../../model/Descriptivo'
import { Filtro } from './../../model/Filtro'
import { Parametrico } from './../../model/Parametrico'
import { ErrorHandler } from './../../utils/ErrorsHandler'

export function RequireMatch(control: AbstractControl) {
    const selection: any = control.value
    if (typeof selection === 'string' && selection) {
        return { incorrect: true }
    }
    return null
}
@Component({
    selector: 'descriptivo-material-selector',
    templateUrl: 'descriptivo-material-selector.component.html',
    styleUrls: ['descriptivo-material-selector.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DescriptivoMaterialSelectorComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DescriptivoMaterialSelectorComponent),
            multi: true,
        },
    ],
})
export class DescriptivoMaterialSelectorComponent implements OnInit, AfterViewInit, ControlValueAccessor, Validator {
    public menuItems: MenuItem[] = []

    @Input()
    public appearence: string = 'outline'
    @Input()
    public newEnt: (desc?) => any = (desc?) => {
        if (this.service) {
            let d = this.service.newEnt()
            if (desc) d['descripcion'] = desc
            return d
        } else {
            return new Descriptivo(null, desc)
        }
    }
    private _inputControl: FormControl = new FormControl(null, [RequireMatch])
    public get inputControl(): FormControl {
        return this._inputControl
    }
    @Input()
    public set inputControl(v: FormControl) {
        this._inputControl = v ? v : new FormControl(null, this.required ? [RequireMatch, Validators.required] : [RequireMatch])
    }
    private _disabled: boolean = false
    public get disabled(): boolean {
        return this._disabled
    }
    @Input()
    public set disabled(v: boolean) {
        this._disabled = v
        this._disabled ? this.inputControl.disable() : this.inputControl.enable()
    }

    public get idioma(): string {
        return this.filter.idioma
    }
    @Input()
    public set idioma(v: string) {
        this.filter.idioma = v
    }

    private _required: boolean = false
    public get required(): boolean {
        return this._required
    }
    @Input()
    public set required(v: boolean) {
        this._required = v
        if (!this.inputControl) return
        if (this._required) {
            this.inputControl.setValidators([Validators.required, RequireMatch])
        } else {
            this.inputControl.setValidators([RequireMatch])
        }
    }

    private onChangeCallback: (_: any) => void = () => {}
    private onTouchedCallback: (_: any) => void = () => {}

    private _options: Descriptivo[] = []

    private _seleccionado: Descriptivo
    public get seleccionado(): Descriptivo {
        return this._seleccionado
    }
    public set seleccionado(val: Descriptivo) {
        const prev = this._seleccionado
        if (val?.key !== this._seleccionado?.key || !val?.key || !this._seleccionado) {
            if (val && val?.key == Parametrico.NUEVO.key) {
                this.onNew.emit()
                this.nuevo(null)
            } else {
                if (val) {
                    this.onTouchedCallback(val)
                    this.onChangeCallback(val)
                    this.onSelect.emit(val)
                    
                }
            }
        }
        this._seleccionado = val
        this.inputControl.setValue(val)
    }

    public filteredOptions: Observable<Descriptivo[]>

    @Input()
    public label: string

    @Input()
    public placeHolder: string

    private _filterf: Filtro = new Filtro(null, {}, 0, 100, 'id', 1, false)
    public get filter(): Filtro {
        return this._filterf
    }
    @Input()
    public set filter(v: Filtro) {
        if (v) {
            this._filterf.patchValue(v)
        }
    }

    @Input()
    public service: DescriptivosService<any>

    private _default: Descriptivo
    public get default(): Descriptivo {
        return this._default
    }
    @Input()
    public set default(v: Descriptivo) {
        this._default = v
        setTimeout(() => {
            if (!this.seleccionado && !this.readonly && v) {
                this.seleccionado = v
            }
            
        }, 100);
        
    }

    @Input()
    public readonly: boolean = false

    @Input()
    public set options(v: Descriptivo[]) {
        this._options = v ? v : []
    }

    @Input()
    public permiteNuevo: boolean = false

    @Input()
    public limpiable: boolean = false

    @Input()
    public showError: boolean = true
    @Input()
    public gestor: TemplateRef<any>

    @Input()
    public itemTemplate: TemplateRef<any>
    @Output() onNew: EventEmitter<any> = new EventEmitter()

    @Output() onRefresh: EventEmitter<any> = new EventEmitter()
    @Output() onSelect: EventEmitter<any> = new EventEmitter()

    public itemEditado = new Descriptivo()
    public editando = false
    @Input()
    public isLazy: boolean = false
    @Input()
    public getData: (filter: Filtro, l?: LoadingService) => Promise<Descriptivo[]> = (f, l) => {
        return this.service ? this.service.getDescriptivos(f, l) : Promise.resolve(f.apply(this.options))
    }
    @Input()
    public name: string
    public contextVisible: boolean = false
    public dataFiltrada: Descriptivo[] = []
    public ctx: any
    public loadingService: LoadingService = new LoadingService()
    
    public get safeDescripcion() : string {
        return this.seleccionado? this.seleccionado.descripcion : ''
    }
    public set safeDescripcion(v : string) {
       
    }

    constructor(private defaultService: DefaultService) {
        this.ctx = {
            itemEditado: this.itemEditado,
            handler: {
                onGuardado: (r) => this.onGuardado(r),
                onCancelado: (r) => this.onCancelar(),
            },
        }
    }
    ngAfterViewInit(): void {

    }
    writeValue(obj: any): void {
        if(obj){
            this._seleccionado = obj
            this.inputControl.setValue(obj)
        }

    }
    registerOnChange(fn: any): void {
        this.onChangeCallback = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }
    public get options(): Descriptivo[] {
        return this._options
    }

    public getOptionText(option: Descriptivo) {
        return option ? option.descripcion : ''
    }

    public calcWith = (val) => {
        return 'auto'
    }
    getErrorMessage(control: AbstractControl) {
        return ErrorHandler.getErrorMessage(control)
    }
    private seleccionarUnico(r) {
        setTimeout(()=>{
            if (r.length == (this.permiteNuevo ? 2 : 1) && (this.inputControl?.value?.length >= 1 || this.required)) {
                this.seleccionado = r[this.permiteNuevo ? 1 : 0]
            }
        },200)
       
    }
    public autoselect(event?) {
        if (event) event.stopPropagation()
        if (this.inputControl.value && typeof this.inputControl.value === 'string') {
            this.filtrar(this.inputControl.value, true).then((r) => {
                if (r) this.seleccionarUnico(r)
            })
        }
    }

    ngOnInit() {
        this.menuItems = [
            {
                label: 'Recordar',
                command: () => {
                    if (this.seleccionado?.codigo) {
                        this.defaultService.setDefault(this.name, this.seleccionado)
                    }
                },
            },
            {
                label: 'Limpiar',
                command: () => {
                    this.clean()
                },
            },
        ]
        if (this.required && !this.inputControl?.value) {
            this.refreshData(null).then((r) => {
                if (r) this.seleccionarUnico(r)
            })
        }

        this.defaultService.getDefault(this.name).then((def) => {
            if (!this.seleccionado?.codigo && !this.readonly && def) {
                this.seleccionado = def
                this.onChangeCallback(def)
            }
        })
    }
    public clean(event?) {
        this.seleccionado = null
        this.defaultService.cleanDefault(this.name)
        if (event) event.stopPropagation()
        this.onChangeCallback(null)
        this.onSelect.emit(null)
    }
    applyResults = (results, soloValores: boolean = false): Descriptivo[] => {
        if (results?.length == (this.permiteNuevo ? 2 : 1) && this.required) {
            this.seleccionado = results[this.permiteNuevo ? 1 : 0]
            //this.inputControl.setValue(results[this.permiteNuevo ? 1 : 0])
        }
        return soloValores ? results : this.generarBase(results)
    }
    public filtrar(value: string, soloValores: boolean = false): Promise<Descriptivo[]> {
        const f = new Filtro(null, {})
        f.searchStr = value ? value.toLowerCase() : ''
        return this.getData(f)
            .then((r) => this.applyResults(r, soloValores))
            .then((r) => {
                this.dataFiltrada = r
                return r
            })
    }

    private generarBase(val: Descriptivo[]) {
        let data = []
        if (this.permiteNuevo) {
            data.push(Parametrico.NUEVO)
        }
        data = data.concat(val)
        return data
    }

    public onFocus(event: any) {
        event.srcElement.select()
    }
    isTablet() {
        const width = window.innerWidth
        return width <= 1024 && width > 640
    }

    isDesktop() {
        return window.innerWidth > 1024
    }

    isMobile() {
        return window.innerWidth <= 640
    }

    public onDialogShow(event, dialog) {
        if (!this.isDesktop()) {
            dialog.maximized = true
        }
    }

    public refreshData(str?: string): Promise<any> {
        if (this.service) {
            if (str) {
                this.filter.searchStr = str
            }
            return this.getData(this.filter, this.loadingService).then((r) => {
                this.options = this.generarBase(r)
                return this.options
            })
        } else {
            this.onRefresh.emit()
            return Promise.resolve(this.options)
        }
    }
    public nuevo(desc?) {
        this.itemEditado = this.newEnt(desc)
        this.ctx['itemEditado'] = this.itemEditado

        this.editando = true
    }
    public onGuardado(item: Descriptivo) {
        this.refreshData().then((r) => {
            this.seleccionado = item
            this.editando = false
            this.itemEditado = this.newEnt()
        })
    }
    public onCancelar() {
        this.itemEditado = new Parametrico()
        this.editando = false
    }
    validate(control: AbstractControl): ValidationErrors | null {
        control.setErrors(control?.valid ? null : control?.errors);
        return !control?.valid ? control.errors : null
    }
}