import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { Filtro } from './../../model/Filtro';

@Component({
    selector: 'filtro',
    templateUrl: 'filtro.component.html',
    styleUrls: ['filtro.component.less'],
})
export class FiltroCompoenent implements OnInit {
    @Input()
    public isMinimized = false

    private _isFixed: boolean = true
    @Input()
    public useSidebar:boolean = false;
    public get isFixed(): boolean {
        return this._isFixed
    }
    @Input()
    public set isFixed(v: boolean) {
        this._isFixed = v
        if (this._isFixed) {
            this.isMinimized = false
        }
    }

    public filterContentCopy: TemplateRef<any>

    public displayFiltros = false
    @Input()
    public filterTemplateContent: TemplateRef<any>;
    public toggleDisplay(event) {
        if (this.isMobile() || this.useSidebar) {
            this.displayFiltros = true
        } else if (this.isFixed) {
            this.isMinimized = false
        } else {
            this.isMinimized = !this.isMinimized
        }

        event.stopPropagation()
    }

    isMobile() {
        return window.innerWidth <= 640
    }
    @Input()
    public filterName: string

    private _filter: Filtro

    public get filter(): Filtro {
        return this._filter
    }
    @Input()
    public set filter(v: Filtro) {
        this._filter = v
    }
    @Input()
    public verBuscador: boolean = true
    @Input()
    public idGlobalFiltro = 'gf'

    @Input()
    public autoSearch: boolean = true

    @Output()
    public onFilterChange: EventEmitter<Filtro> = new EventEmitter()

    @Output()
    public onFiltroLoaded: EventEmitter<Filtro> = new EventEmitter()

    @Output()
    public onSearch: EventEmitter<Filtro> = new EventEmitter()

    @Output()
    public onClean: EventEmitter<Filtro> = new EventEmitter()

    @Input()
    public table: any

    public onFocus(event: any) {
        event.srcElement.select()
    }
    ngOnInit() {}
    public changeState(event) {
        this.isMinimized = !this.isMinimized
        event.stopPropagation()
        event.preventDefault()
    }
    public search() {
        this.onSearch.emit(this.filter)
    }
    public resetFilter() {
        this.filter.clean()
        this.onClean.emit(this.filter)
    }
    public filterGlobal(event, match){
        return this.table.filterGlobal((event.target as HTMLInputElement).value,match)
    }
}
