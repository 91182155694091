import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { NovedadService } from './novedades.service';
import { ParametricoService } from './parametricos.service';
import { Moneda } from '../model/Moneda';
@Injectable({
    providedIn: 'root',
})
export class MonedaService extends ParametricoService<Moneda>{
    // static cotizaciones: Map<String, Cotizacion[]> = new Map();

    constructor(novedadesService: NovedadService, public authService: AuthService) {
        super(novedadesService, authService);
    }

   /* async getCotizacion(monedaDesde: Moneda, monedaHasta: Moneda) {
        if (!monedaDesde || !monedaHasta || (monedaDesde.codigo == monedaHasta.codigo)) return 1;

        const c = await this.cotizacionService.getCotizacion(monedaDesde, monedaHasta);

        return c?.valor || null;

    }*/
    public baseName(): string {
        return "moneda";
    }
    public parseToEnt(data: any): Moneda {
        return Moneda.fromData(data);
    }
    public newEnt(): Moneda {
        return new Moneda();
    }
    // protected loadData(customLoading?: LoadingService): Promise<Moneda[]> {
    //     var p = super.loadData(customLoading);
    //     MonedaService.cotizaciones = new Map();
    //     MonedaService.cotizaciones.set("P", [new Cotizacion('D', 0.014),
    //     new Cotizacion('E', 0.011)
    //     ]);
    //     MonedaService.cotizaciones.set("E", [new Cotizacion('D', 1.18),
    //     new Cotizacion('P', 86.96)
    //     ]);
    //     MonedaService.cotizaciones.set("D", [new Cotizacion('E', 0.85),
    //     new Cotizacion('P', 73.72)
    //     ]);
    //     return p;

    // }

}