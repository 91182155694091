<div class="gestor-parametrico content  content-white" *ngIf="itemEditado && service">
    <model-gestor [readonly]="readonly" [service]="service" [(item)]="itemEditado" [validar]="isValid" 
        (onItemGuardado)="onGuardado($event)" (onCancelar)="onCancelado()" [goBack]="goBack" [isModal]="isModal"
        [title]="title">
        <div class="gestor-form grid" model-type="dataContent">
            <div class="col-12 lg:col-2">
                <span class="p-float-label">
                    
                    <input pInputText  placeholder="{{'PH_CODIGO' | translate}}" [(ngModel)]="itemEditado.codigo"
                        [disabled]="!editable || itemEditado?.esSistema">
                        <label>{{'CODIGO' | translate}}</label>
                </span>
            </div>
            <div class="col-12 lg:col-8">
                <span class="p-float-label">
                    
                    <input pInputText  placeholder="{{'PH_DESCRIPCION' | translate}}" [(ngModel)]="itemEditado.descripcion"
                        required [disabled]="!editable">
                        <label>{{'DESCRIPCION' | translate}}</label>
                </span>
            </div>
            <div class="col-12 lg:col-2">
                <span class="p-float-label">
                    <input pInputText TYPE="number" placeholder="{{'PH_PESO' | translate}}" [(ngModel)]="itemEditado.peso"
                        [disabled]="!editable" [disabled]="itemEditado?.esSistema">                        
                    <label>{{'PESO' | translate}}</label>
                </span>
            </div>
        </div>
    </model-gestor>

</div>