<div class="grid grid-nogutter  align-items-center justify-content-center layout-picker">
    <div class="col-fixed" >
        <button
            pButton
            class="p-button-outlined"
            (click)="layoutElegido = 'L'"
            icon="pi pi-list"
            [disabled]="readonly"
            [ngClass]="{
                'p-button-success': layoutElegido === 'L',
                'p-button-outlined': layoutElegido !== 'L'
            }"
        ></button>
    </div>
    <div class="col-fixed button-wrapper">
        <button
            pButton
            (click)="layoutElegido = 'G'"
            icon="pi pi-table"
            [disabled]="readonly"
            [ngClass]="{
                'p-button-success': layoutElegido === 'G',
                'p-button-outlined': layoutElegido !== 'G'
            }"
        ></button>
    </div>
</div>
