
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { ConfiguracionUsuario } from '../model/ConfiguracionUsuario';

import { AuthService } from './auth.service';
import { BaseService } from './base.service';
import { LoadingService } from './loading-data-service.service';
import { NovedadService } from './novedades.service';
@Injectable({
    providedIn: 'root',
})
export class ConfiguracionUsuarioService extends BaseService {
    constructor(private authService: AuthService, private nService: NovedadService) {
        super()
        this.init();
    }

   
    public clear(name: string) {
        this.config.defaults.delete(name);
        this.save();
    }
    public save(customLoading?: LoadingService) {
        if (this.config) {
            if (customLoading) { customLoading.addLoadingCount() } else this.loadingService.addLoadingCount();
            this.http.put(this.getBaseURL(), this.config.json).toPromise().then(c => {
                this.config = ConfiguracionUsuario.fromData(c);
                this.configObs.next(this.config);
                return this.config;
            }).finally(() => {
                if (customLoading) { customLoading.susLoadingCount() } else this.loadingService.susLoadingCount();
            })
        }


    }
    public baseName(): string {
        return "configuracion-usuario";
    }
    public parseToEnt(data: any): ConfiguracionUsuario {
        return ConfiguracionUsuario.fromData(data);
    }
    public newEnt(): ConfiguracionUsuario {
        return new ConfiguracionUsuario();
    }
    public configObs: BehaviorSubject<ConfiguracionUsuario> = new BehaviorSubject(null);
    private sus: Subscription[] = [];

    public override init() {
        this.authService.onLogout.subscribe(f => {
            if (f) {
                this.sus.forEach(s => s.unsubscribe());
                this.sus = [];
            }


        })
        this.sus.push(this.authService.getUser().pipe(filter(u => u != undefined)).subscribe(u => {
            if (this.config != u.configuracion) {
                this.config = u.configuracion;
                this.configObs.next(this.config);
            }
        }));
    }
    private config: ConfiguracionUsuario;
    public get(): Promise<ConfiguracionUsuario> {
        return Promise.resolve(this.config);
    }


}